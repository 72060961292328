// Import gql helper and craft a GraphQL query
import { gql } from "graphql-tag";

import { API, graphqlOperation } from "aws-amplify";
function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

const FlightsQuery = (airline = "440") => {
  return /* GraphQL */ `
query MyQuery {
  listFlights(filter: {
    airlineID: {eq: "${airline}"},
    active: {ne: false}
  }) {
    items {
      airlineID
      id
      location
      number
    }
  }
}
`;
};

const CodesQuery = /* GraphQL */ `
query CodesList {
  listSecondarys(filter: {active: {ne: false }}}) {
    items {
      id
      number
      text
    }
  }
}
`;
const AirlinesQuery = () => {
  return `
query AirlinesList {
  listAirlines(filter: {active: {ne: false }}) {
    items {
      id
      name
      abbreviation
    }
  }
}
`;
};

export const getCodes = () => API.graphql(graphqlOperation(CodesQuery));
export const getAirlines = () =>
  API.graphql({ query: AirlinesQuery(), authMode: "API_KEY" });
export const getFlightsByAirline = (airline) =>
  API.graphql({ query: FlightsQuery(airline), authMode: "API_KEY" });
