import logo from "../../customs.png";
import { generateYears } from "../options";

const cn_strings = {
  options: {
    russiaCities: [
      "莫斯科",
      "伯力",
      "薩哈林斯克",
      "伊爾庫茨克",
      "海參崴",
      "其他",
    ],
    taiwanCities: ["台北", "台中", "桃園", "新竹", "高雄", "其他"],
    chinaCities: [
      "上海",
      "北京",
      "廣州",
      "杭州",
      "大連市",
      "南京",
      "哈爾濱",
      "鄭州",
      "沉陽",
      "成都市",
      "其他",
    ],
    koreaCities: [
      "漢城",
      "釜山",
      "仁川/京畿道",
      "大邱",
      "大田",
      "光州",
      "蔚山",
      "其他",
    ],
    japanCities: [
      "關東",
      "近紀",
      "中部",
      "北海道",
      "東北",
      "越信",
      "北陸",
      "中國",
      "四國",
      "九州",
      "沖繩",
      "其他",
    ],
    guamStatus: [
      "乗り継ぎ",
      "機組人員 – 抵境中",
      "機組人員 – 轉機中",
      "帰国するグアム居住者",
    ],
    status: [
      "訪客",
      "轉機",
      "機組人員 – 抵境中",
      "機組人員 – 轉機中",
      "長期居留–搬遷至關島至少一年以上",
      "回國居民",
    ],
    country: [
      "關島",
      "美國",
      "日本",
      "韓國",
      "中國",
      "台灣",
      "澳洲",
      "北馬利安納群島",
      "歐洲",
      "密克羅尼西亞",
      "香港",
      "印度",
      "馬來西亞",
      "帛琉",
      "菲律賓",
      "馬紹爾群島",
      "新加坡",
      "俄國",
      "其他",
    ],
    year: generateYears(),
    month: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    day: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    reason: [
      "度假",
      "結婚",
      "蜜月",
      "會議",
      "獎勵旅遊",
      "其他商務目的",
      "拜訪親友",
      "政府或軍事目的",
      "校園旅遊",
      "運動賽事",
      "醫療",
      "其他",
    ],
    length: [
      "數小時中途停留",
      "一日遊",
      "一晚",
      "兩晚",
      "三晚",
      "四晚",
      "五晚",
      "六晚",
      "七到十晚",
      "十一到十五晚",
      "十六到三十晚",
      "一到三個月",
      "三個月到一年",
    ],
    trip: ["第一次", "第二次", "第三次", "第四次", "第五次", "第六次或以上"],
    arrangement: ["旅行業者", "旅遊網站", "直接與航空公司/飯店預定"],
    stay: [
      "飯店",
      "假期出租",
      "郵輪",
      "朋友或親戚",
      "民宿",
      "軍用設施",
      "分時渡假住房",
      "其他",
    ],
  },
  DD: "日",
  MM: "月",
  YYYY: "年",
  dob: "出生日期",
  heading: `![logo](${logo})\n\n# 關島海關與檢疫局\n\n## 電子申報表格\n\n`,
  healthFormDescription:
    "## 健康申明表格\n\n## 每位入境旅客皆須提交完整的健康申明表格（每位乘客需提交一份） 請以英文完成下列表格",
  intendedResidentDescription: "# 預期居民",
  nameFieldLabel: "您的全名",
  firstNameLabel: "名",
  middleNameLabel: "中間名",
  lastNameLabel: "姓",
  packingListTitleLabel:
    "## 貨物申報\n\n 警告：將管制物品進口到關島屬於重罪（第9 GCA條，第67節）。違反本節規定，將判處最高十（10）年的有期徒刑。對海關官員的虛假陳述將受到法律制裁。您的所有行李，手袋和手提物品都可能被打開和檢查。如果您對必須報告或聲明的內容有任何疑問，請詢問海關官員。為防止危險的農業害蟲或管制的野生生物進入，以下為管制和/或限制項目：水果，蔬菜，植物，植物產品，土壤，肉類，肉類產品，鳥類，蝸牛和其他活體動物或動物產品。不申報此類物品可能會被查收充公和/或額外罰款。關島《國內稅收法》要求您聲明在國外購得的所有商品，物品或商品，無論是否二手、應稅的，以及無論是否以禮品目的購買獲得的。煩請聲明所有商品或商品。",
  bringingLabel: "我（我們）帶到關島",
  substancesLabel: "管制物品（處方或其他）",
  firearmsLabel: "槍枝（個人擁有）",
  explosivesLabel: "炸藥（商業或消費等級，包括煙火）",
  animalsLabel: "動物，部分動物，或是野生動物製成商品",
  animalProductsLabel: "動物產品，包括肉，牛奶或雞蛋",
  plantsLabel: "植物或部分植物，包括新鮮水果，蔬菜，種子花或植物材料製成的物品",
  soilLabel: "土壤材料或樣品，或生物標本",
  serviceAnimalLabel: "現場服務性動物",
  cigarettesLabel: "超過五（5）條香煙（1,000支香煙）或十二（12）包其他煙草製品",
  alcoholLabel: "每位成人超過一（1）加侖/3.7升酒精飲料",
  welcomeText:
    "### Håfa Adai! 非常感謝您選擇關島為您下一個旅遊目的地。\n為了您的安全，我們要求所有入境旅客都填寫這份電子申報表格。請在抵境前詳讀以下資訊並填寫表格以確保同意遵守美國與關島的法律。若您謊報，將有可能依法受罰。\n\n 為了您的方便，此表格可以在抵達關島前72小時完成。",
  welcomeButtonText: "我準備好要填寫表格了。",
  visitorSpecificDescriptionLabel: "# 訪客／轉機／居民資訊",
  onlineBookingLabel: "請問您是使用網路預訂旅程的嗎？",
  genderLabel: "性別",
  emailLabel: "電子信箱地址",
  TelephoneNumber: "電話號碼",
  NationalityLabel: "國籍",
  WorkplaceLabel: "工作地點",
  OccupationLabel: "職業",
  SymptomsLabel: "請勾選在過去14天內曾出現或現有的症狀：",
  FeverLabel: "發燒（°F/C）",
  FeverAdviceText: "（請十四天曾經發燒再填寫，若無可留空）",
  Headache: "頭痛",
  Cough: "咳嗽",
  SoreThroat: "喉嚨痛",
  BodyWeakness: "體虛",
  JointMusclePain: "關節或肌肉痛",
  BruisingBleeding: "瘀青/流血",
  Rashes: "疹子",
  RedEyes: "紅眼",
  Nausea: "噁心",
  Vomiting: "嘔吐",
  Diarrhea: "腹瀉",
  DifficultybreathingShortnessofbreath: "呼吸困難/呼吸短促",
  Other: "其他",
  healthWorkerLabel: "請問您是否有造訪過任何衛生工作者，醫院，診所或療養院？",
  poultryFarmLabel: "請問您是否有造訪過任何家禽農場或動物市場",
  contactRespiratoryLabel:
    "您或您的家人是否與發燒，咳嗽和/或呼吸系統疾病的人保持密切聯繫？",
  sickFamilyGroupLabel: "您的家庭/團體中是否有其他患病的人？",
  recentMedicationLabel: "請列出過去4-6小時內服用的藥物：",
  dphssText:
    "關島公共衛生社會福利部(DPHSS)的任務是保護關島免於與健康有關的威脅，這些威脅會使我們的島嶼社區曝曬於極大的風險下。在公共衛生緊急事件中，健康檢查人員將被授權來向您詢問先前的旅行歷史和健康信息。\n\n虛假陳述可依法懲處。\n\n 我已根據關島法律對偽證罪之處罰，確認我已閱讀並理解此表格的要求以及我所作的書面陳述均為真實和正確的",
  promoLabel: "如果您希望從關島觀光局獲得更多的資訊",
  descriptionLabel:
    "# 個人訊息\n每個入境旅客或監護人必須以英文填寫以下資訊（每個家庭只需提供一份表格）。「家庭」的定義為住在同一個屋子內，有血緣關係、婚姻關係、家庭關係，或是領養關係的家庭成員。",
  todaysDateLabel: "抵達關島日期",
  crewLabel: "Crew",
  airlineLabel: "航空公司",
  flightLabel: "航班號碼",
  flightCityLabel: "國家/城市",
  countryLabel: "國家",
  originatingCountryLabel: "出發地（旅程起始國家）",
  originatingCityLabel: "出發城市（旅程起始城市）",
  familyMembersLabel: "一同旅行的家庭成員（包含您）總人數",
  passportCountryLabel: "發照國家",
  passportNoLabel: "護照號碼/旅行ID號碼",
  placeOfResidenceLabel: "主要居住地–國家/城市（只有適用的城市）",
  arrivalInformationLabel: "## 抵達資訊",
  addressOnGuamLabel: "地址/飯店名稱（關島居住地）",
  statusLabel: "旅遊目的",
  transitFormDescription: "# 轉機",
  residentFormDescription: "# 回國居民",
  returningReasonLabel: "剛完成的這趟旅程的主要目的地",
  transitingDestination: "最終目的地",
  resaleTitle: "# 商業商品",
  currencyVisitorCrewTitle: "# 貨幣",
  over10kLabel: "我有（我們有）超過十萬（$10,000）美金或等值的外幣",
  resaleLabel: "我有（我們有）Commercial Merchandise（可買賣之貨品）",
  remainLabel:
    "訪客–將留在關島內所有物品的總價值（僅商業商品；轉售商品）為（美元）",
  declarationDescription:
    "# 貨物申報\n關島海關和檢疫局負責保護關島免於進口非法和違禁物品。海關官員有權提問並檢查您和您的個人財產。如果被選中檢查，您將得到禮貌，專業且有尊嚴的待遇。海關官員值班主管或主管可以回答您的問題。\n\n警告：將管制物品進口到關島屬於重罪（第9 GCA條，第67節）。違反本節規定，將判處最高十（10）年的有期徒刑。對海關官員的虛假陳述將受到法律制裁。您的所有行李，手袋和手提物品都可能被打開和檢查。如果您對必須報告或聲明的內容有任何疑問，請詢問海關官員。為防止危險的農業害蟲或管制的野生生物進入，以下為管制和/或限制項目：水果，蔬菜，植物，植物產品，土壤，肉類，肉類產品，鳥類，蝸牛和其他活體動物或動物產品。不申報此類物品可能會被查收充公和/或額外罰款。關島《國內稅收法》要求您聲明在國外購得的所有商品，物品或商品，無論是否二手、應稅的，以及無論是否以禮品目的購買獲得的。煩請聲明所有商品或商品。",
  declarationPreviousLabel: "在上一頁中，您對以下其中一項聲明了“是”：",
  declarationDescriptionLabel: "請描述您所想要申報的貨品",
  consentDescriptionLabel:
    "## 認證聲明\n\n 關島海關和檢疫局負責保護關島免於進口非法和違禁物品。海關官員有權提問並檢查您和您的個人財產。如果被選中檢查，您將得到禮貌、專業且尊重的待遇。若您有任何問題，請洽海關人員或是值班主管。",
  consentCertifyFieldLabel:
    "我確認我已閱讀並理解此表格的要求，並同意我所作的所有口頭和書面陳述均屬真實聲明。",
  Yes: "是",
  No: "否",
  lengthOfStayLabel: "居留長度",
  tripDescription: "這趟到關島的旅程是您的：",
  arrangementsLabel: "您的旅程是透過以下何種方式預定？",
  placeOfStayLabel: "您在關島滯留的時候會住在？（請勾選所有適用選項）",
  nextLabel: "下一頁",
  prevLabel: "上一頁",
  resetLabel: "重置",
  AdditionalLabelText: "## 額外的家庭成員",
  FullNameText: "全名",
  tripReasonLabelText: "旅途主要目的",
  EmailCollectionText:
    "如果您希望未來可以收到關島觀光局所傳送的活動以及推廣通知，請提供您的電話號碼，以及/或是電子信箱地址",
  PhoneFieldLabel: "電話號碼",
  EveryFieldLabel: "您必須回答所有問題而得以繼續填寫表格",
  DeclarationNALabel:
    "您已經表明您沒有將任何所列商品帶入關島。請在下面列出您要申報的其他商品",
  GoodsDescriptionLabel: "貨品說明",
  YesOrNoLabel: "請選擇是或否",
  EnterValueLabel: "請輸入一個數值",
  ResidentsTotalValueLabel:
    "居民–所有商品（包括從國外購買的商業商品，包括帶入關島的禮物）的總價值為（美元）",
  SeatNumberLabel: "座位號碼",
  CountriesVisitedLabel: "請勾選以下過去14天內所造訪或轉機的國家",
  ChinaVisitedLabel: " 中國",
  IranVisitedLabel: "伊朗",
  ItalyVisitedLabel: "義大利",
  SouthKoreaVisitedLabel: "南韓",
  JapanVisitedLabel: "日本",
  HongKongVisitedLabel: "香港",
  MacaoSARVisitedLabel: "澳門",
  VietnamVisitedLabel: "越南",
  ThailandVisitedLabel: "泰國",
  PhilippinesVisitedLabel: "菲律賓",
  MalaysiaVisitedLabel: "馬來西亞",
  TaiwanVisitedLabel: "台灣",
  CambodiaVisitedLabel: "柬埔寨",
  SingaporeVisitedLabel: "新加坡",
  NewZealandVisitedLabel: "紐西蘭",
  AustraliaVisitedLabel: "澳洲",
  CanadaVisitedLabel: "加拿大",
  AmericanSamoaVisitedLabel: "美屬薩摩亞",
  MarshallIslandsVisitedLabel: "馬紹爾群島共和國",
  FSMVisitedLabel: "密克羅尼西亞",
  CNMIVisitedLabel: "北馬利安納群島邦",
  PalauVisitedLabel: "帛琉",
  USVisitedLabel: "美國（請註明）",
  OtherVisitedLabel: "其他（請註明）",
  Person2: "### 第一位成員",
  Person3: "### 第二位成員",
  Person4: "### 第三位成員",
  Person5: "### 第四位成員",
  Person6: "### 第五位成員",
  Person7: "### 第六位成員",
  Person8: "### 第七位成員",
  City: "城市",
  required: "必要",
  acceptError: "🚫 您必須同意才能繼續",
  Male: "男",
  Female: "女",
  QRCode: "QR碼",
  presentCode:
    "感謝您填寫關島海關電子申報表。請列印/儲存您的QR碼，以呈現給關島CQA人員",
  finish: "完成",
  thankYou: "謝謝",
  seeYouSoon: "我們期待與您",
  languages: {
    selectLanguage: "選擇語言",
    languagePrompt: "我們提供了多種語言的關島EDF，請選擇最方便的一種",
    en: "英語 (English)",
    jp: "日本人 (日本語)",
    kr: "韓國人 (한국어)",
    cn: "繁體中文",
  },
  countries: {
    AF: "阿富汗",
    AX: "奧蘭群島",
    AL: "阿爾巴尼亞",
    DZ: "阿爾及利亞",
    AS: "美屬薩摩亞",
    AD: "安道爾",
    AO: "安哥拉",
    AI: "安圭拉島",
    AQ: "南極洲",
    AG: "安提瓜和巴布達",
    AR: "阿根廷",
    AM: "亞美尼亞",
    AW: "阿魯巴島",
    AU: "澳大利亞",
    AT: "奧地利",
    AZ: "阿塞拜疆",
    BS: "巴哈馬",
    BH: "巴林",
    BD: "孟加拉國",
    BB: "巴巴多斯",
    BY: "白俄羅斯",
    BE: "比利時",
    BZ: "伯利茲",
    BJ: "貝寧",
    BM: "百慕大",
    BT: "不丹",
    BO: "玻利維亞（多民族國）",
    BQ: "博內爾，聖尤斯特歇斯和薩巴",
    BA: "波斯尼亞和黑塞哥維那（簡稱：波黑",
    BW: "博茨瓦納",
    BV: "布維島",
    BR: "巴西",
    IO: "英屬印度洋領地",
    BN: "文萊達魯薩蘭國",
    BG: "保加利亞",
    BF: "布基納法索",
    BI: "布隆迪",
    CV: "佛得角",
    KH: "柬埔寨",
    CM: "喀麥隆",
    CA: "加拿大",
    KY: "開曼群島",
    CF: "中非共和國",
    TD: "乍得",
    CL: "智利",
    CN: "中國",
    CX: "聖誕島",
    CC: "科科斯（基林）群島",
    CO: "哥倫比亞",
    KM: "科摩羅",
    CG: "剛果",
    CD: "剛果（民主共和國）",
    CK: "庫克群島",
    CR: "哥斯達黎加",
    CI: "科德",
    HR: "克羅地亞",
    CU: "古巴",
    CW: "庫拉索",
    CY: "塞浦路斯",
    CZ: "捷克語",
    DK: "丹麥",
    DJ: "吉布地",
    DM: "多米尼加",
    DO: "多明尼加共和國",
    EC: "厄瓜多爾",
    EG: "埃及",
    SV: "薩爾瓦多",
    GQ: "赤道幾內亞",
    ER: "厄立特里亞",
    EE: "愛沙尼亞",
    SZ: "埃斯瓦蒂尼",
    ET: "埃塞俄比亞",
    FK: "福克蘭群島（馬爾維納斯）",
    FO: "法羅群島",
    FJ: "斐濟",
    FI: "芬蘭",
    FR: "法國",
    GF: "法屬圭亞那",
    PF: "法屬波利尼西亞",
    TF: "法屬南部領地",
    GA: "加蓬",
    GM: "岡比亞",
    GE: "喬治亞州",
    DE: "德國",
    GH: "加納",
    GI: "直布羅陀",
    GR: "希臘",
    GL: "格陵蘭",
    GD: "格林納達",
    GP: "瓜德羅普島",
    GU: "關島",
    GT: "危地馬拉",
    GG: "根西島",
    GN: "幾內亞",
    GW: "幾內亞比紹",
    GY: "圭亞那",
    HT: "海地",
    HM: "希爾德島和麥當勞群島",
    VA: "教廷",
    HN: "洪都拉斯",
    HK: "香港",
    HU: "匈牙利",
    IS: "冰島",
    IN: "印度",
    ID: "印度尼西亞",
    IR: "伊朗（伊斯蘭共和國）",
    IQ: "伊拉克",
    IE: "愛爾蘭",
    IM: "馬恩島",
    IL: "以色列",
    IT: "意大利",
    JM: "牙買加",
    JP: "日本",
    JE: "球衣",
    JO: "約旦",
    KZ: "哈薩克斯坦",
    KE: "肯尼亞",
    KI: "基里巴斯",
    KP: "韓國（民主人士",
    KR: "韓國（共和國）",
    KW: "科威特",
    KG: "吉爾吉斯斯坦",
    LA: "老撾人民",
    LV: "拉脫維亞",
    LB: "黎巴嫩",
    LS: "萊索托",
    LR: "利比里亞",
    LY: "利比亞",
    LI: "列支敦士登",
    LT: "立陶宛",
    LU: "盧森堡",
    MO: "澳門",
    MK: "馬其頓（前南斯拉夫共和國）",
    MG: "馬達加斯加",
    MW: "馬拉維",
    MY: "馬來西亞",
    MV: "馬爾代夫",
    ML: "馬里",
    MT: "馬耳他",
    MH: "馬紹爾群島",
    MQ: "馬提尼克島",
    MR: "毛里塔尼亞",
    MU: "毛里求斯",
    YT: "馬約特島",
    MX: "墨西哥",
    FM: "密克羅尼西亞（聯邦國）",
    MD: "摩爾多瓦（共和國）",
    MC: "摩納哥",
    MN: "蒙古",
    ME: "黑山共和國",
    MS: "蒙特塞拉特",
    MA: "摩洛哥",
    MZ: "莫桑比克",
    MM: "緬甸",
    NA: "納米比亞",
    NR: "瑙魯",
    NP: "尼泊爾",
    NL: "荷蘭",
    NC: "新喀裡多尼亞",
    NZ: "新西蘭",
    NI: "尼加拉瓜",
    NE: "尼日爾",
    NG: "奈及利亞",
    NU: "紐埃",
    NF: "諾福克島",
    MP: "北馬里亞納群島",
    NO: "挪威",
    OM: "阿曼",
    PK: "巴基斯坦",
    PW: "帛琉",
    PS: "巴勒斯坦國",
    PA: "巴拿馬",
    PG: "巴布亞新幾內亞",
    PY: "巴拉圭",
    PE: "秘魯",
    PH: "菲律賓",
    PN: "皮特凱恩",
    PL: "波蘭",
    PT: "葡萄牙",
    PR: "波多黎各",
    QA: "卡塔爾",
    RE: "團圓",
    RO: "羅馬尼亞",
    RU: "俄羅斯聯邦",
    RW: "盧旺達",
    BL: "聖巴托洛繆島",
    SH: "聖海倫娜，提升和特里斯坦達庫尼亞",
    KN: "聖基茨和尼維斯",
    LC: "聖盧西亞",
    MF: "聖馬丁（法語部分）",
    PM: "聖皮埃爾和密克隆群島",
    VC: "聖文森特和格林納丁斯",
    WS: "薩摩亞",
    SM: "聖馬力諾",
    ST: "聖多美和普林西比",
    SA: "沙特阿拉伯",
    SN: "塞內加爾",
    RS: "塞爾維亞",
    SC: "塞舌爾",
    SL: "塞拉利昂",
    SG: "新加坡",
    SX: "聖馬丁（荷蘭語部分）",
    SK: "斯洛伐克",
    SI: "斯洛文尼亞",
    SB: "所羅門群島",
    SO: "索馬里",
    ZA: "南非",
    GS: "南喬治亞島和南桑威奇群島",
    SS: "南蘇丹",
    ES: "西班牙",
    LK: "斯里蘭卡",
    SD: "蘇丹",
    SR: "蘇里南",
    SJ: "斯瓦爾巴群島和揚馬延",
    SE: "瑞典",
    CH: "瑞士",
    SY: "阿拉伯敘利亞共和國",
    TW: "台灣",
    TJ: "塔吉克斯坦",
    TZ: "坦桑尼亞聯合共和國",
    TH: "泰國",
    TL: "東帝汶",
    TG: "多哥",
    TK: "托克勞",
    TO: "湯加",
    TT: "特立尼達和多巴哥",
    TN: "突尼斯",
    TR: "火雞",
    TM: "土庫曼斯坦",
    TC: "特克斯和凱科斯群島",
    TV: "圖瓦盧",
    UG: "烏干達",
    UA: "烏克蘭",
    AE: "阿拉伯聯合酋長國",
    GB: "大不列顛及北愛爾蘭聯合王國",
    US: "美國",
    UM: "美國本土外小島嶼",
    UY: "烏拉圭",
    UZ: "烏茲別克斯坦",
    VU: "瓦努阿圖",
    VE: "委內瑞拉（玻利瓦爾共和國）",
    VN: "越南",
    VG: "維爾京群島（英國）",
    VI: "維爾京群島（美國）",
    WF: "瓦利斯和富圖納群島",
    EH: "西撒哈拉",
    YE: "也門",
    ZM: "贊比亞",
    ZW: "辛巴威",
  },
  wantEmail:
    "如果您希望將此QR Code的副本發到您的電子信箱，請在下方輸入您的電子信箱地址。",
  keepOpen: "若不需要，請在您的裝置上保持開啟此頁面。",
  showCode:
    "感謝您填寫關島海關電子申報表。 請向關島CQA人員出示以下顯示的QR碼。",
  QREmailSubject: "您的QR碼以進入關島",
  hasErrors: "請確保您已填寫表格",
  NonLatinCharacters: "以下字符不是英語：",
  errorOccured: "發生了錯誤。請再試一次。",
  emailSent: "電子郵件發送成功",
};
export default cn_strings;
