import DOBFields from "./dobFields";
import { options } from "./options";

// Name, DOB, Passport#, Country of Issuance

const person = (name) => {
  const currentPerson = `Person${name + 1}`; // Person2 - Person8
  return {
    personLabel: {
      name: "PersonLabel" + name,
      text: `{{{${currentPerson}}}}`,
      // text: `### Person ${(name + 1)}`,
      component: "Text",
    },
    passportNo: {
      name: "PassportNoField" + name,
      label: "{{{passportNoLabel}}}",
      component: "LatinOnlyTextField",
      required: true,
      block: false,
      hidden: true,
    },
    passportCountry: {
      name: "PassportCountryField" + name,
      label: "{{{passportCountryLabel}}}",
      component: "TranslatedCountryField",
      required: true,
      hidden: true,
    },
    nameFields: [
      {
        name: "FullNameLabel" + name,
        text: "{{FullNameText}}",
        // text: "Full Name",
        component: "Text",
      },
      {
        name: "FirstNameField" + name,
        label: "{{{firstNameLabel}}}",
        component: "LatinOnlyTextField",
        block: false,
        required: true,
        hidden: true,
      },
      {
        name: "MiddleNameField" + name,
        label: "{{{middleNameLabel}}}",
        component: "LatinOnlyTextField",
        block: false,
        // required: true,
        hidden: true,
      },
      {
        name: "LastNameField" + name,
        label: "{{{lastNameLabel}}}",
        component: "LatinOnlyTextField",
        block: true,
        required: true,
        hidden: true,
      },
    ],
    dob: [
      {
        component: "Text",
        text: "{{dob}}", //TODO
        name: "dobLabel" + name,
      },
      {
        name: "DOBDayField" + name,
        label: "{{{DD}}}",
        component: "SelectField",
        options: options.days,
        block: false,
        required: true,
        hidden: true,
        autocomplete: false,
      },
      {
        name: "DOBMonthField" + name,
        label: "{{MM}}",
        component: "SelectField",
        options: options.months,
        block: false,
        required: true,
        hidden: true,
        autocomplete: false,
      },
      {
        name: "DOBYearField" + name,
        label: "{{YYYY}}",
        component: "SelectField",
        options: options.years,
        block: true,
        required: true,
        hidden: true,
        autocomplete: false,
      },
    ],
    gender: {
      component: "SelectField",
      name: `Gender${name}`,
      label: "{{{genderLabel}}}",
      options: [
        { label: "{{{Male}}}", value: "m" },
        { label: "{{{Female}}}", value: "f" },
      ],
      block: true,
      hidden: true,
      required: true,
      autocomplete: false,
    },
  };
};

export default person;
