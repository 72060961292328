import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "../form-control";
import attach from "../attach";
import HelpToolTip from "./help-tool-tip";
import FormLabel from "./form-label";
import { LangContext } from "../../lib/LangContext";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  label: {
    [theme.breakpoints.down("md")]: {
      position: "static",
    },
  },
});

class CommonField extends React.PureComponent {
  static contextType = LangContext;
  render() {
    const {
      component,
      children,
      label,
      required,
      fullWidth,
      err,
      editable,
      touched,
      help,
      hideLabelUI,
      hideLabel,
      useDisplayValue,
      shrinkLabel,
      inlineLabel,
      marginBottom,
      autoHideLabel,
      classes,
    } = this.props;
    const { translate } = this.context;

    let fld = null;

    let lbl = null;

    const isBlank = component.isBlank();

    const id = component.getUniqueId();
    if (!hideLabelUI && !hideLabel && (!autoHideLabel || label)) {
      if (editable && !useDisplayValue && !inlineLabel) {
        lbl = (
          <InputLabel
            htmlFor={id}
            error={touched && err ? true : false}
            // If label is blank then don't show as required
            required={label && required}
            shrink={shrinkLabel}
            className={classes.label}
          >
            {translate(label)}
          </InputLabel>
        );
      } else {
        // We display a non-breaking space when the label is empty so that CompositeFields, like the
        // ChainedSelectField, print all their display values on the same line.
        const labelText = label ? label : "\u00A0";

        lbl = (
          <FormLabel
            htmlFor={id}
            error={touched && err ? true : false}
            required={required && !useDisplayValue && editable}
            shrink={useDisplayValue || !editable || shrinkLabel}
          >
            {translate(labelText)}
          </FormLabel>
        );

        if (!inlineLabel) {
          // We wrap the label in a div tag so that the proceeding display value appears on a
          // different line
          lbl = <div>{lbl}</div>;
        }
      }
    }

    const firstErr = component.getFirstErr();

    fld = (
      <span>
        {lbl}
        {children}
        {help && editable ? <HelpToolTip help={help} /> : ""}
        {touched && err ? (
          <FormHelperText error>
            {translate(
              /\{\{.*\}\}/.test(firstErr) ? firstErr : `{{{${firstErr}}}}`
            )}
          </FormHelperText>
        ) : null}
      </span>
    );

    if (editable || !isBlank) {
      return (
        <FormControl fullWidth={fullWidth} marginBottom={marginBottom}>
          {fld}
        </FormControl>
      );
    } else {
      return null;
    }
  }
}

// 'value' is needed in the event we are showing the display value
CommonField = attach([
  "label",
  "required",
  "fullWidth",
  "value",
  "err",
  "editable",
  "touched",
  "help",
  "hideLabel",
  "useDisplayValue",
  "autoHideLabel",
])(withStyles(styles, { withTheme: true })(CommonField));

export default CommonField;
