import { options } from "./options";
import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";
import moment from "moment-timezone";

import { loadValues, saveValues, resetValues, resetForm } from "./session";
import AirlineFlights from "./AirlineFlights";
import StatusField from "./statusField";
import PlaceOfResidenceField from "./PlaceOfResidenceField";
import idFields from "./idFields";
import person from "./person";
import SubmitButton from "./submitButton";

const todayString = moment.tz("Pacific/Guam").format("MM/DD/YYYY");
// const todayDate = moment.tz(moment.utc(), 'Pacific/Guam').startOf('day');
const todayDate = moment
  .tz(moment.utc(todayString), "Pacific/Guam")
  .startOf("day");
const minDate1 = todayDate.clone().subtract(3, "days");
const minDate2 = todayDate.clone().subtract(2, "days");
const minDate = todayDate.clone().subtract(1, "days");
const maxDate = todayDate.clone().add(1, "days");
const maxDate1 = todayDate.clone().add(2, "days");
const maxDate2 = todayDate.clone().add(3, "days");

const todayValues = [
  // {
  //     name: "YesterdayMinus2",
  //     label: minDate2.format('MMMM D, YYYY'),
  //     value: minDate2.toISOString()
  // },
  // {
  //     name: "YesterdayMinus1",
  //     label: minDate1.format('MMMM D, YYYY'),
  //     value: minDate1.toISOString()
  // },
  {
    name: "Yesterday",
    label: minDate.format("MMMM D, YYYY"),
    value: minDate.toISOString(),
  },
  {
    name: "Today",
    label: todayDate.format("MMMM D, YYYY"),
    value: todayDate.toISOString(),
  },
  {
    name: "Tomorrow",
    label: maxDate.format("MMMM D, YYYY"),
    value: maxDate.toISOString(),
  },
  {
    name: "TomorrowPlus1",
    label: maxDate1.format("MMMM D, YYYY"),
    value: maxDate1.toISOString(),
  },
  {
    name: "TomorrowPlus2",
    label: maxDate2.format("MMMM D, YYYY"),
    value: maxDate2.toISOString(),
  },
];

const personFields = (series) => {
  const personSet = person(series);
  return [
    personSet.personLabel,
    personSet.passportNo,
    personSet.passportCountry,
    ...personSet.nameFields,
    ...personSet.dob,
    personSet.gender,
  ];
};
const placeOfResidenceField = PlaceOfResidenceField();

const familyMembers = compiler.newComponent({
  name: "FamilyMembersField",
  label: "{{{familyMembersLabel}}}",
  required: true,
  component: "SelectField",
  fullWidth: true,
  // blankString: "None",
  options: [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
  ],
  autocomplete: false,
});

function setFamilyMembersVisibility() {
  const fields = [
    "PersonLabel",
    "FullNameLabel",
    "dobLabel",
    "PassportNoField",
    "PassportCountryField",
    "FirstNameField",
    "MiddleNameField",
    "LastNameField",
    "DOBDayField",
    "DOBMonthField",
    "DOBYearField",
    "Gender",
  ];
  form.getField("additionalLabel")._set("hidden", true);
  for (let i = 1; i <= 8; i++) {
    fields.forEach((field) => {
      form.getField(`${field}${i}`)._set("hidden", true);
      if (field.includes("Label")) return;
      form.getField(`${field}${i}`)._set("required", false);
    });
  }
  const current = familyMembers.getValue();
  if (current > 1) form.getField("additionalLabel")._set("hidden", false);
  for (let i = 1; i < current; i++) {
    fields.forEach((field) => {
      form.getField(`${field}${i}`)._set("hidden", false);
      if (field.includes("Label") || field.includes("MiddleNameField")) return;
      form.getField(`${field}${i}`)._set("required", true);
    });
  }
  /* 
  Added to avoid ghost entries:
  Usually the form entries are cached, so when the primary traveller fills the form again
  and lowers the number of secondary travellers, the extra traveller information is also saved.
  Now, we are clearing all data if value in dropdown is decreased.
  */
  if (current) {
    for (let i = current; i <= 8; i++) {
      fields.forEach((field) => {
        if (!field.includes("Label"))
          form.getField(`${field}${i}`).setValue(null);
      });
    }
  }
}

familyMembers.on("$change", () => {
  if (familyMembers.getValue()) {
    setFamilyMembersVisibility();
  }
});

const startingCountry = compiler.newComponent({
  component: "TranslatedCountryField",
  name: "OriginatingFromField",
  label: "{{{originatingCountryLabel}}}",
  fullWidth: true,
  required: true,
});

const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});

const airlineThings = AirlineFlights();
const formJson = {
  component: "Form",
  name: "app.CustomerForm",
  listeners: [
    {
      event: "value",
      actions: [
        {
          component: "Emit",
          event: "OriginCountry",
          value: "{{{fields.CountryOfOriginField.value}}}",
        },
      ],
    },
  ],
  fields: [
    {
      name: "Language",
      component: "LanguageField",
      required: false,
    },
    {
      name: "DescriptionField",
      text: "{{{heading}}}{{{descriptionLabel}}}",
      component: "Text",
    },
    ...idFields.passportAndName,
    {
      name: "ArrivalDate",
      label: "{{{todaysDateLabel}}}",
      required: true,
      component: "SelectField",
      options: todayValues,
      // autocomplete: false
      // minDate: minDate,
      // maxDate: maxDate,
      // component: "DateField",
      // maxDate: 1606631100000,
    },
    {
      name: "ArrivalInformationField",
      text: "{{{arrivalInformationLabel}}}",
      component: "Text",
    },
    ...airlineThings.Fields,
    startingCountry,
    {
      component: "LatinOnlyTextField",
      label: "{{{originatingCityLabel}}}",
      name: "OriginatingFromCityField",
      fullWidth: true,
    },
    ...placeOfResidenceField,
    ...idFields.dob,
    idFields.gender,
    idFields.addressOnGuam,
    StatusField,
    familyMembers,
    {
      component: "Text",
      text: "{{AdditionalLabelText}}",
      name: "additionalLabel",
    },
    ...personFields(1),
    ...personFields(2),
    ...personFields(3),
    ...personFields(4),
    ...personFields(5),
    ...personFields(6),
    ...personFields(7),
    ...personFields(8),
    SubmitButton,
    resetButton,
  ],
};

let form = compiler.newComponent(formJson);

form["submit"] = () => {
  saveValues(form);
  form.setDirty(false);
  let v = StatusField.getValue();
  // globals.redirect("/"+v);
  globals.redirect("/" + v);
};

form.on("OriginCountry", (value) => {
  // runs when origin country changes (thanks, Owen)
  if (String(value) === "01") {
    // GUAM
    StatusField._set("options", options.guamStatusOptions);
  } else {
    StatusField._set("options", options.statusOptions);
  }
});
form.on("load", (f) => {
  loadValues(form);
  setFamilyMembersVisibility();
});
form.on("reset", (f) => {
  resetValues(form);
});

export { form };
