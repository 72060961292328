import compiler from "mson/lib/compiler";

import { options } from "./options";

const cities = options.cities;

const PlaceOfResidenceField = (
  fieldName = "CountryOfOriginField",
  fieldLabel = "{{{placeOfResidenceLabel}}}"
) => {
  const label = compiler.newComponent({
    name: "ResidenceLabel",
    component: "Text",
    // disabled: true,
    // block: true,
    text: `${fieldLabel}`,
    // value: `${fieldLabel} - country/city (city only where applicable)`,
    // fullWidth: true
  });
  const country = compiler.newComponent({
    name: fieldName,
    component: "SelectField",
    label: "{{countryLabel}}",
    options: [...options.countryOptions],
    block: false,
    required: true,
    autocomplete: false,
  });
  const city = compiler.newComponent({
    name: fieldName + "City",
    component: "SelectField",
    label: "{{{City}}}",
    options: [],
    hidden: true,
    block: true,
    autocomplete: false,
  });
  const otherCity = compiler.newComponent({
    name: fieldName + "OtherCity",
    component: "LatinOnlyTextField",
    label: "{{{City}}}",
    hidden: true,
    block: true,
  });

  country.on("$change", () => {
    const countryValue = country.get("value");
    if (countryValue === "Other") {
      country._set("block", false);
      otherCity._set("hidden", false);
      // otherCity._set('required', true);
      city._set("value", null);
      city._set("hidden", true);
      city._set("required", false);
    } else {
      otherCity._set("hidden", true);
      otherCity._set("value", null);
      if (!countryValue || !cities[countryValue]) {
        country._set("block", true);
        city._set("value", null);
        city._set("hidden", true);
        city._set("required", false);
      } else {
        country._set("block", false);
        city._set("options", cities[countryValue]);
        city._set("hidden", false);
        city._set("required", true);
      }
    }
  });
  return [label, country, city, otherCity];
};

export default PlaceOfResidenceField;
