import render from "./mson-react-bootstrap/render";
import compiler from "mson/lib/compiler";
import "typeface-roboto";

// internal imports
import CheckboxField from "./lib/checkboxField";
import LanguageField from "./lib/languageField";
import TranslatedCountryField from "./lib/translated-country-field";
import SingleCheckboxField from "./lib/single-checkbox-field";
import LatinOnlyTextField from "./lib/LatinOnlyTextField";

import { form } from "./lib/form";
import { visitorSpecific } from "./lib/visitor_specific";
import { visitorPackingList } from "./lib/packingList";
import { welcome } from "./lib/welcome";
import { currencyVisitorCrew } from "./lib/currencyVisitorCrew";
import { Resale } from "./lib/resale";
import { declarationOfGoods } from "./lib/declarationOfGoods";
import { consentForm } from "./lib/consentForm";
import { transit } from "./lib/transit";
import { intendedResident } from "./lib/intendedResident";
import { resident } from "./lib/resident";
import health from "./lib/health";

import "./lib/app.css";

const MenuComponent = compiler.newComponent({
  component: "Menu",
  name: "app.Menu",

  items: [
    {
      path: "/",
      label: "Welcome",
      content: welcome,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/welcome",
      label: "Welcome",
      content: welcome,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/form/:redirect/:visitordata",
      name: "Form_with_Visitor",
      label: "GUAM CUSTOMS AND QUARANTINE AGENCY Mandatory Customs Declaration",
      content: form,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/form/:redirect",
      name: "Form",
      label: "GUAM CUSTOMS AND QUARANTINE AGENCY Mandatory Customs Declaration",
      content: form,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/transiting",
      label: "Transiting Specific Information",
      content: transit,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/crew",
      label: "Crew Specific Information",
      content: visitorPackingList,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/crew/:crewType",
      label: "Crew Specific Information",
      content: visitorPackingList,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/visitor",
      label: "Visitor Specific Information",
      content: visitorSpecific,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/visitor-packing-list",
      label: "Visitor Packing List",
      content: visitorPackingList,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/currency-visitor-crew",
      label: "Currency Visitor, Crew",
      content: currencyVisitorCrew,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/declaration-of-goods",
      label: "Declaration of Goods",
      content: declarationOfGoods,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/resale",
      label: "Declaration of Resale Goods",
      content: Resale,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/consent-form",
      label: "Consent Form",
      content: consentForm,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/resident",
      label: "Resident Form",
      content: resident,
      fullScreen: true,
      hidden: true,
    },
    {
      path: "/intended_resident",
      label: "Intended Resident Form",
      content: intendedResident,
      fullScreen: true,
      hidden: true,
    },
  ],
});

const app = compiler.newComponent({
  name: "app.App",
  component: "App",
  menu: MenuComponent,
});

render(app);
