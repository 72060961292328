import React, { useContext } from "react";
import AppUI from "./app";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProviderTranslated } from "./MuiThemeProviderTranslated";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import globals from "mson/lib/globals";
import attach from "./attach";
import theme from "../theme";
import { LangContext, LangProvider } from "../lib/LangContext";
import SetLang from "../lib/SetLang";
import { Reset } from "../reset";
import { Code } from "../code";
import { Language } from "../language";
import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import LanguageIcon from "@material-ui/icons/Language";

const TopBar = ({ classes }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { translate } = useContext(LangContext);
  return (
    <AppBar position="fixed" className={classes.appBar + " noPrint"}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.menuButton}>
          {pathname !== "/language" && pathname !== "/kiosk/start" ? (
            <Button
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => push("/language")}
            >
              {translate("{{{languages.selectLanguage}}}")}
            </Button>
          ) : null}
        </div>
      </Toolbar>
    </AppBar>
  );
};

const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: "64px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 12,
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  main: {
    position: "relative",
    width: "100%",
    padding: theme.spacing(3),
    height: "calc(100% - 56px)",
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 64px)",
      marginTop: 64,
    },
    overflowAnchor: "none",
  },
});
// Note: BrowserRouter needs to be outside of App so that we can use withRouter
class AppContainer extends React.Component {
  constructor() {
    super();
  }
  onNavigate = (message, callback) => {
    globals.onNavigate(message, callback);
  };

  render() {
    const { component, basename, classes } = this.props;
    return (
      <LangProvider>
        <MuiThemeProviderTranslated theme={theme}>
          <CssBaseline />
          <BrowserRouter
            getUserConfirmation={this.onNavigate}
            basename={basename}
          >
            <TopBar classes={classes} />
            {/* <SetLang mini={true} className="noPrint" /> */}
            <Switch>
              <Route path="/kiosk">
                <Switch>
                  <Route exact path={["/kiosk/", "/kiosk"]}>
                    <Redirect to="/kiosk/start" />
                  </Route>
                  <Route exact path="/kiosk/start">
                    <Language
                      redirectTo="/kiosk/welcome"
                      className={classes.main}
                      seedLanguage="en"
                    />
                  </Route>
                  <Route exact path="/kiosk/reset">
                    <Reset pathRoot="/kiosk/" className={classes.main} />
                  </Route>
                  <Route exact path="/kiosk/code/:codeText/:name">
                    <Code
                      pathRoot="/kiosk/"
                      className={classes.main}
                      autoPrint={true}
                    />
                  </Route>
                  <Route>
                    <AppUI component={component} className={classes.main} />
                  </Route>
                </Switch>
              </Route>
              <Route path="">
                <Switch>
                  <Route exact path="/language">
                    <Language className={classes.main} />
                  </Route>
                  <Route exact path="/reset">
                    <Reset className={classes.main} />
                  </Route>
                  <Route exact path="/code/:codeText/:name">
                    <Code className={classes.main} askToSend={true} />
                  </Route>
                  <Route>
                    <AppUI component={component} className={classes.main} />
                  </Route>
                </Switch>
              </Route>
            </Switch>
            {/* <AppUI component={component} /> */}
          </BrowserRouter>
        </MuiThemeProviderTranslated>
      </LangProvider>
    );
  }
}

export default attach(["basename"])(
  withStyles(styles, { withTheme: true })(AppContainer)
);
