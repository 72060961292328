import { options } from "./options";
const DOBFields = [
  {
    name: "DOBDayField",
    label: "{{DD}}",
    component: "SelectField",
    options: options.days,
    block: false,
    required: true,
    autocomplete: false,
  },
  {
    name: "DOBMonthField",
    label: "{{MM}}",
    component: "SelectField",
    options: options.months,
    block: false,
    required: true,
    autocomplete: false,
  },
  {
    name: "DOBYearField",
    label: "{{YYYY}}",
    component: "SelectField",
    options: options.years,
    block: true,
    required: true,
    autocomplete: false,
  },
];
export default DOBFields;
