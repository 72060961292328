import kr_strings from "./languages/kr_strings";
import jp_strings from "./languages/jp_strings";
import cn_strings from "./languages/cn_strings";
import en_strings from "./languages/en_strings";
const stringValues = {
  kr: kr_strings,
  jp: jp_strings,
  cn: cn_strings,
  en: en_strings,
};

const getStrings = (language) => {
  return stringValues[language] || stringValues["en"];
};
export { getStrings };
