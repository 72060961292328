import { options } from "./options";
import compiler from "mson/lib/compiler";

const StatusField = compiler.newComponent({
  name: "StatusField",
  label: "{{{statusLabel}}}",
  required: true,
  options: options.statusOptions,
  component: "SelectField",
  fullWidth: true,
  autocomplete: false,
});

export default StatusField;
