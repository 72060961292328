import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

import { loadValues, saveValues, resetValues, resetForm } from "./session";
import { LengthOfStay } from "./lengthOfStay";
import { TripToGuam } from "./tripToGuam";
import { TravelArrangements } from "./travelArrangements";
import { PlaceOfStay } from "./placeOfStay";
import { TripReason } from "./tripReason";
import navButtons from "./navButtons";
import { NextDestination, PreviousDestination } from "./destinations";

const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  block: false,
});

const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  // block: true
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});

const form = compiler.newComponent({
  component: "Form",
  name: "app.TransitForm",
  fields: [
    {
      name: "DescriptionField",
      text: "{{{heading}}}{{{transitFormDescription}}}",
      component: "Text",
    },
    LengthOfStay,
    {
      component: "LatinOnlyTextField",
      name: "TransitFinalDestination",
      label: "{{{transitingDestination}}}",
      required: true,
    },
    previousButton,
    {
      name: "SubmitButton",
      label: "{{{nextLabel}}}", //TODO
      type: "submit",
      component: "ButtonField",
      block: false,
    },
    resetButton,
  ],
});

form["submit"] = () => {
  saveValues(form);
  form.setDirty(false);
  globals.redirect(NextDestination(form));
};

previousButton.on("click", () => {
  form.setDirty(false);
  history.back();
});

form.on("load", (f) => {
  loadValues(form);
});
form.on("reset", (f) => {
  resetValues(form);
});

export { form as transit };
