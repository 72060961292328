import FullNameFields from "./fullNameFields";
import DOBFields from "./dobFields";

const idFields = {
  passportAndName: [
    {
      name: "PassportNoField",
      label: "{{{passportNoLabel}}}",
      component: "LatinOnlyTextField",
      required: true,
      block: false,
    },
    {
      name: "PassportCountryField",
      label: "{{{passportCountryLabel}}}",
      component: "TranslatedCountryField",
      required: true,
    },
    ...FullNameFields,
  ],
  dob: [
    {
      component: "Text",
      text: "{{dob}}",
    },
    ...DOBFields,
  ],
  addressOnGuam: {
    name: "AddressOnGuam",
    label: "{{{addressOnGuamLabel}}}",
    component: "LatinOnlyTextField",
    required: true,
    fullWidth: true,
  },
  gender: {
    component: "SelectField",
    name: "Gender",
    label: "{{{genderLabel}}}",
    options: [
      { label: "{{{Male}}}", value: "m" },
      { label: "{{{Female}}}", value: "f" },
    ],
    block: false,
    required: true,
    autocomplete: false,
  },
};

export default idFields;
