import { API } from "aws-amplify";
import { emailQRCode } from "../graphql/mutations";
import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Logo from "../customs.png";
import { LangContext } from "../lib/LangContext";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Paper,
  Snackbar,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import classnames from "classnames";
import CustomsLogo from "../customs.png";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  form: {
    margin: "auto",
    textAlign: "center",
  },
  name: {
    textAlign: "center",
    margin: "auto",
    marginBottom: "2em",
    marginTop: "2em",
  },
  code: {
    textAlign: "center",
    margin: "auto",
    width: "256px",
    height: "256px",
  },
  button: {
    marginTop: "33px",
    width: "calc(256px * 1.5)",
    maxWidth: "100%",
  },
  paper: {
    padding: "2em",
    width: "calc(256px * 2.0)",
    maxWidth: "100%",
    margin: "2em auto",
  },
  emailField: {
    width: "100%",
  },
  paragraph: {
    marginBottom: "0.5em",
  },
});

export const Code = withStyles(styles, { withTheme: true })(
  ({
    classes,
    pathRoot = "/",
    className,
    autoPrint = false,
    askToSend = false,
  }) => {
    const { push } = useHistory();
    const { codeText, name } = useParams();
    const [email, setEmail] = useState("");
    const { translate, lang } = useContext(LangContext);
    const [hideEmailer, setHideEmailer] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackType, setSnackType] = useState(undefined);
    const onSubmit = async () => {
      if (email) {
        try {
          await API.graphql({
            query: emailQRCode,
            authMode: "API_KEY",
            variables: {
              QRCodeBase: codeText,
              Name: name,
              EmailAddress: email,
              Language: lang,
            },
          });
          setSnackType("success");
          setHideEmailer(true);
        } catch (err) {
          setSnackType("error");
        } finally {
          setSnackbarOpen(true);
        }
      } else {
        push(`${pathRoot}reset`);
      }
    };

    useEffect(() => {
      setTimeout(function () {
        if (autoPrint) {
          window.print();
        }
      }, 300);
    });

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setSnackbarOpen(false);
    };

    return (
      <main className={className}>
        <ValidatorForm
          onSubmit={onSubmit}
          onError={(errors) => console.log(errors)}
        >
          <div className={classnames([classes.form, "form"])}>
            <h4>{translate("{{QRCode}}")}</h4>
            <p>
              {translate(autoPrint ? "{{{presentCode}}}" : "{{{showCode}}}")}
            </p>
            <p></p>
            <hr noshade="true" />
            <div id="printout" className={classes.printOut}>
              <p id="customerName" className={classnames(classes.name)}>
                {name}
              </p>
              <div id="qrcode" className={classes.code}>
                <QRCode
                  value={codeText}
                  level="H"
                  imageSettings={{ src: CustomsLogo, width: 80, height: 80 }}
                  renderAs={"svg"}
                  size={256}
                ></QRCode>
              </div>
            </div>
            {askToSend ? (
              !hideEmailer ? (
                <Paper className={classes.paper}>
                  <Typography className={classes.paragraph}>
                    {translate("{{wantEmail}}")}
                  </Typography>
                  <Typography className={classes.paragraph}>
                    {translate("{{keepOpen}}")}
                  </Typography>
                  <TextValidator
                    label={translate("{{emailLabel}}")}
                    name="email"
                    value={email}
                    className={classes.emailField}
                    onChange={(event) => setEmail(event.target.value)}
                    validators={["isEmail"]}
                    errorMessages={["Email is not valid"]}
                  />
                  <Button
                    type="submit"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={!email}
                  >
                    {translate("{{emailLabel}}")}
                  </Button>
                </Paper>
              ) : null
            ) : (
              <Button
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
              >
                {translate("{{finish}}")}
              </Button>
            )}
          </div>
        </ValidatorForm>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={7000}
          onClose={handleClose}
        >
          {snackType === "error" ? (
            <Alert severity="error">
              {translate("{{errorOccured}} {{keepOpen}}")}
            </Alert>
          ) : (
            <Alert severity="success">{translate("{{emailSent}}")}</Alert>
          )}
        </Snackbar>
      </main>
    );
  }
);
