import { getValue } from "./session";

const packing = {
  crew: "/form/true",
  "crew/terminating": "/form/true",
  "crew/in-transit": "/form/true",
  transiting: "/transiting",
  visitor: "/visitor",
  resident: "/resident",
  intended_resident: "/intended_resident",
};
const packingListBack = () => {
  const status = getValue("app.CustomerForm", "StatusField");
  return packing[status];
};

const nextPath = {
  "app.ResidentForm": "/visitor-packing-list",
  "app.VisitorSpecificForm": "/visitor-packing-list",
  "app.TransitForm": "/visitor-packing-list",
  "app.PackingList": "/declaration-of-goods",
  // "app.PackingList": "/currency-visitor-crew",
  "app.DeclarationForm": "/consent-form",
  "app.IntendedResident": "/visitor-packing-list",
  // "app.ConsentForm": "/code.html?codeText="
  "app.ConsentForm": "/health/1",
};
const previousPath = {
  "app.IntendedResident": "/form/true",
  "app.ResidentForm": "/form/true",
  "app.TransitForm": "/form/true",
  "app.VisitorSpecificForm": "/form/true",
  // "app.VisitorPackingList": packingListBack(),
  "app.PackingList": packingListBack(),
  "app.CurrencyVisitorCrewForm": "/visitor-packing-list",
  "app.DeclarationForm": "/visitor-packing-list",
  // "app.DeclarationForm": "/currency-visitor-crew",
  "app.ConsentForm": "/resale",
  // "app.ConsentForm": "/declaration-of-goods"
};

const PreviousDestination = (formObject) => {
  const name = formObject._name;
  return previousPath[name];
};
const NextDestination = (formObject) => {
  const name = formObject._name;
  return nextPath[name];
};
export { NextDestination, PreviousDestination };
