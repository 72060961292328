const JapanCities = [
  { value: "01Japan", label: "Kanto", parentValue: "15" },
  { value: "02Japan", label: "Kinki", parentValue: "15" },
  { value: "03Japan", label: "Chubu", parentValue: "15" },
  { value: "04Japan", label: "Hokkaido", parentValue: "15" },
  { value: "05Japan", label: "Tohoku", parentValue: "15" },
  { value: "06Japan", label: "Koshinetsu", parentValue: "15" },
  { value: "07Japan", label: "Hokuriku", parentValue: "15" },
  { value: "15Japan", label: "Chugoku", parentValue: "15" },
  { value: "09Japan", label: "Shikoku", parentValue: "15" },
  { value: "10Japan", label: "Kyushu", parentValue: "15" },
  { value: "11Japan", label: "Okinawa", parentValue: "15" },
  { value: "12Japan", label: "Other", parentValue: "15" },
];
export default JapanCities;
