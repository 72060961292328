import compiler from "mson/lib/compiler";
import { options } from "./options";

const app = compiler.newComponent({
  name: "TripReasonField",
  label: "{{{tripReasonLabelText}}}",
  // required: true,
  options: options.reasonOptions,
  component: "SelectField",
  fullWidth: true,
  autocomplete: false,
});

export { app as TripReason };
