import JapanCities from "./JapanCities";

const RussiaCities = [
  { value: "01Russia", label: "Moscow", parentValue: "19" },
  { value: "02Russia", label: "Khabarovsk", parentValue: "19" },
  { value: "03Russia", label: "Y-Sakhalinsk", parentValue: "19" },
  { value: "04Russia", label: "Irkutsk", parentValue: "19" },
  { value: "05Russia", label: "Vladivostok", parentValue: "19" },
  { value: "06Russia", label: "Other", parentValue: "19" },
];

const TaiwanCities = [
  { value: "01Taiwan", label: "Taipei", parentValue: "18" },
  { value: "02Taiwan", label: "Taichung", parentValue: "18" },
  { value: "03Taiwan", label: "Taoyuan", parentValue: "18" },
  { value: "04Taiwan", label: "Hsinchu", parentValue: "18" },
  { value: "05Taiwan", label: "Kaohsiung", parentValue: "18" },
  { value: "06Taiwan", label: "Other", parentValue: "18" },
];

const ChinaCities = [
  { value: "01China", label: "Shanghai", parentValue: "17" },
  { value: "02China", label: "Beijing", parentValue: "17" },
  { value: "03China", label: "Guangzhou", parentValue: "17" },
  { value: "04China", label: "Hangzhou", parentValue: "17" },
  { value: "05China", label: "Dalian", parentValue: "17" },
  { value: "06China", label: "Nanjing", parentValue: "17" },
  { value: "07China", label: "Harbin", parentValue: "17" },
  { value: "08China", label: "Zhengzhou", parentValue: "17" },
  { value: "09China", label: "Shenyang", parentValue: "17" },
  { value: "10China", label: "Chengdu", parentValue: "17" },
  { value: "11China", label: "Other", parentValue: "17" },
];

const KoreaCities = [
  { value: "01Korea", label: "Seoul", parentValue: "16" },
  { value: "02Korea", label: "Busan", parentValue: "16" },
  { value: "03Korea", label: "Incheon/Gyeonggi", parentValue: "16" },
  { value: "04Korea", label: "Daegu", parentValue: "16" },
  { value: "05Korea", label: "Daejeon", parentValue: "16" },
  { value: "06Korea", label: "Gwangju", parentValue: "16" },
  { value: "07Korea", label: "Ulsan", parentValue: "16" },
  { value: "08Korea", label: "Other", parentValue: "16" },
];

const translate = (lookupTitle) => (item, index) => ({
  ...item,
  label: `{{{options.${lookupTitle}.${index}}}}`,
});

/**
 *
 * @returns List of dict with label = 1900 till current year and value = 1900 till current year
 */
function generateYearsWithLabel() {
  var listYears = [];
  var currentYear = new Date().getFullYear();
  for (var year = currentYear; year >= 1900; year--) {
    listYears.push({ label: year, value: year.toString() });
  }
  return listYears;
}

/**
 *
 * @returns List of year numbers from 1900 till current year
 */
export function generateYears() {
  var listYears = [];
  var currentYear = new Date().getFullYear();
  for (var year = currentYear; year >= 1900; year--) {
    listYears.push(year);
  }

  return listYears;
}

export const options = {
  guamStatusOptions: [
    { label: "Transiting", value: "transiting" },
    { label: "Crew - Terminating", value: "crew/terminating" },
    { label: "Crew - In Transit", value: "crew/in-transit" },
    { label: "Returning Resident", value: "resident" },
  ].map(translate("guamStatus")),
  statusOptions: [
    { label: "Visitor", value: "visitor" },
    { label: "Transiting", value: "transiting" },
    { label: "Crew - Terminating", value: "crew/terminating" },
    { label: "Crew - In Transit", value: "crew/in-transit" },
    {
      label: "Intended Resident - moving to Guam for at least one year",
      value: "intended_resident",
    },
    { label: "Returning Resident", value: "resident" },
  ].map(translate("status")),
  countryOptions: [
    { label: "Guam", value: "01" },
    { label: "USA", value: "13" },
    { label: "Japan", value: "15" },
    { label: "Korea", value: "16" },
    { label: "China", value: "17" },
    { label: "Taiwan", value: "18" },
    { label: "Australia", value: "02" },
    { label: "CNMI", value: "03" },
    { label: "Europe", value: "04" },
    { label: "FSM", value: "05" },
    { label: "Hong Kong", value: "06" },
    { label: "India", value: "07" },
    { label: "Malaysia", value: "08" },
    { label: "Palau", value: "09" },
    { label: "Philippines", value: "10" },
    { label: "RMI", value: "11" },
    { label: "Singapore", value: "12" },
    { label: "Russia", value: "19" },
    { label: "Other", value: "Other" },
  ].map(translate("country")),
  years: generateYearsWithLabel().map(translate("year")),
  months: [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ].map(translate("month")),
  days: [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
  ].map(translate("day")),
  reasonOptions: [
    { label: "Pleasure/Vacation", value: "pleasure" },
    { label: "To get married", value: "married" },
    { label: "Honeymoon", value: "honeymoon" },
    { label: "Meeting/Conference", value: "conference" },
    { label: "Incentive Trip", value: "incentive" },
    { label: "Other Business", value: "business" },
    { label: "Visiting friends or relatives", value: "friends" },
    { label: "Government or Military", value: "military" },
    { label: "School Trip", value: "school" },
    { label: "Sports Event", value: "sports" },
    { label: "Medical", value: "Medical" },
    { label: "Other", value: "Other" },
  ].map(translate("reason")),
  lengthOptions: [
    { label: "Just a few hours layover", value: "layover" },
    { label: "For a day trip", value: "day_trip" },
    { label: "1 night", value: "1" },
    { label: "2 nights", value: "2" },
    { label: "3 nights", value: "3" },
    { label: "4 nights", value: "4" },
    { label: "5 nights", value: "5" },
    { label: "6 nights", value: "6" },
    { label: "7 – 10 nights", value: "7" },
    { label: "11 – 15 nights", value: "11" },
    { label: "16 – 30 nights", value: "16" },
    { label: "1 – 3 months", value: "month" },
    { label: "3 months – 1 year", value: "year" },
  ].map(translate("length")),
  tripOptions: [
    { label: "1st", value: "1" },
    { label: "2nd", value: "2" },
    { label: "3rd", value: "3" },
    { label: "4th", value: "4" },
    { label: "5th", value: "5" },
    { label: "6th or more", value: "6" },
  ].map(translate("trip")),
  arrangementOptions: [
    { label: "Travel Agent", value: "agent" },
    { label: "Travel Website", value: "website" },
    { label: "Direct Booking (Airline/Accommodation)", value: "direct" },
  ].map(translate("arrangement")),
  stayOptions: [
    { label: "Hotel", value: "hotel" },
    { label: "Vacation Rental", value: "rental" },
    { label: "Cruise Ship", value: "ship" },
    { label: "Friends or Relatives", value: "friends" },
    { label: "Bed and Breakfast", value: "bandb" },
    { label: "Military Facility", value: "military" },
    { label: "Timeshare", value: "timeshare" },
    { label: "Other", value: "other" },
  ].map(translate("stay")),
  cities: {
    19: RussiaCities.map(translate("russiaCities")),
    18: TaiwanCities.map(translate("taiwanCities")),
    17: ChinaCities.map(translate("chinaCities")),
    15: JapanCities.map(translate("japanCities")),
    16: KoreaCities.map(translate("koreaCities")),
  },
};
