import logo from "../../customs.png";
import { generateYears } from "../options";

const kr_strings = {
  options: {
    russiaCities: [
      "모스크바",
      "하바롭스크",
      "유즈노사할린스크",
      "이르쿠츠크",
      "블라디보스토크",
      "기타",
    ],
    taiwanCities: ["타이페이", "타이중", "타오위안", "신주", "가오슝", "기타"],
    chinaCities: [
      "상하이",
      "베이징",
      "광저우",
      "항저우",
      "다롄",
      "난징",
      "하얼빈",
      "정저우",
      "선양",
      "청두",
      "기타",
    ],
    koreaCities: [
      "서울",
      "부산",
      "인천/경기",
      "대구",
      "대전",
      "광주",
      "울산",
      "기타",
    ],
    japanCities: [
      "간토",
      "긴키",
      "주부",
      "홋카이도",
      "도호쿠",
      "고신에쓰",
      "호쿠리쿠",
      "주고쿠",
      "시코쿠",
      "규슈",
      "오키나와",
      "기타",
    ],
    guamStatus: [
      "환승객",
      "승무원 - 비행종료",
      "승무원 - 환승",
      "괌 귀환 거주자",
    ],
    status: [
      "방문객",
      "환승객",
      "승무원 - 비행종료",
      "승무원 - 환승",
      "괌 거주 예정자 - 최소 1년 이상 체류",
      "괌 귀환 거주자",
    ],
    country: [
      "괌",
      "미국",
      "일본",
      "대한민국",
      "중국",
      "대만",
      "호주",
      "북마리아나제도",
      "유럽",
      "미크로네시아 연방",
      "홍콩",
      "인도",
      "말레이시아",
      "팔라우",
      "필리핀",
      "마셜제도",
      "싱가포르",
      "러시아",
      "기타",
    ],
    year: generateYears(),
    month: [
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월",
    ],
    day: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    reason: [
      "여가/휴가",
      "결혼",
      "신혼여행",
      "미팅/컨퍼런스",
      "인센티브 여행",
      "기타 사업",
      "친구/친척 방문",
      "정부/군 관련 업무",
      "학교 현장학습",
      "스포츠 행사",
      "의료",
      "기타",
    ],
    length: [
      "몇 시간 경유",
      "당일",
      "1박",
      "2박",
      "3박",
      "4박",
      "5박",
      "6박",
      "7-10박",
      "11-15박",
      "16-30박",
      "1-3개월",
      "3개월-1년",
    ],
    trip: [
      "첫 번째",
      "두 번째",
      "세 번째",
      "네 번째",
      "다섯 번째",
      "여섯 번 이상",
    ],
    arrangement: ["여행사", "여행 웹사이트", "직접 예약(항공/숙박)"],
    stay: [
      "호텔",
      "게스트 하우스",
      "크루즈 선박",
      "친구 혹은 친척집",
      "베드 엔 브렉퍼스트",
      "군사 시설",
      "타임쉐어",
      "기타",
    ],
  },
  nextLabel: "다음",
  prevLabel: "이전",
  resetLabel: "초기화",
  DD: "일",
  MM: "월",
  YYYY: "년도",
  dob: "출생년도",
  heading: `![logo](${logo})\n\n# 괌 세관 및 검역 본부\n\n## 전자 세관 신고서\n\n`,
  nameFieldLabel: "이름",
  firstNameLabel: "이름",
  middleNameLabel: "중간이름",
  lastNameLabel: "성",
  welcomeText:
    "### 하파데이!  괌을 방문해 주셔서 감사합니다.\n귀하의 안전을 위해 괌에 입국하는 모든 승객은 괌 전자 세관 신고서를 의무적으로 작성하여야 합니다.  다음의 내용을 읽고 미국 및 괌 법률에 따라 도착 전 해당 양식을 작성해주시기 바랍니다. 허위 기재 시 법적 처벌을 받을 수 있습니다. \n\n귀하의 편의를 위해 입국일로부터 72시간 이내에 신고서를 작성해주시기 바랍니다. ",
  welcomeButtonText: "본인(나)는 신고서를 작성할 준비가 되었습니다.",

  genderLabel: "성별",
  descriptionLabel:
    "# 개인정보 \n모든 여행객 혹은 가족 구성원 대표는 다음 정보를 반드시 영문으로 제공해야 합니다 (신고서는 가족당 한 장만 작성). “가족”은 동일 세대에 거주하는 혈연, 결혼 (배우자), 가족 관계 및 입양가족 구성원을 뜻합니다.",
  todaysDateLabel: "괌 도착 날짜",
  airlineLabel: "항공사/선박",
  countryLabel: "출생국가",
  originatingCountryLabel: "출발지 (출발한 국가)",
  originatingCityLabel: "출발지 (출발한 도시)",
  familyMembersLabel: "동반 가족 수(본인 포함)",
  passportCountryLabel: "여권 발행 국가",
  passportNoLabel: "여권번호/ID번호",
  placeOfResidenceLabel: "주 체류지-국가/도시 (도시는 해당 시 기재)",
  arrivalInformationLabel: "## 도착 정보",
  addressOnGuamLabel: "괌 체류시 주소/호텔명",
  statusLabel: "방문 유형",
  AdditionalLabelText: "## 추가 가족 구성원",
  FullNameText: "이름",
  promoLabel: "괌정부관광청으로부터 이벤트 소식을 받기 원하신다면",
  flightLabel: "항공/선박편명",
  transitFormDescription: "# 환승객",
  currencyVisitorCrewTitle: "# 통화",
  medicationLabel: "지난 4-6시간 내에 복용한 약물을 기재해 주시기 바랍니다.",
  packingListTitleLabel:
    "## 물품신고서\n\n경고: 금지 물품을 괌으로 반입하는 것은 불법입니다. (GCA 9장 67조). 이 조항을 위반할 경우 10년 이하의 의무 징역 선고를 받게 되며 세관원에게 허위 진술을 할 경우 법에 의해 처벌을 받게 됩니다. 핸드백을 포함한 모든 수화물은 열어서 검사할 수 있습니다. 신고대상 품목은 세관원에게 문의하시기 바랍니다. 위험한 농업 해충 및 금지된 야생 생물의 반입을 금지하기 위해 다음과 같은 품목이 금지됩니다. 금지 품목: 과일, 채소, 식물, 식물 제품, 흙, 육류, 육류 제품, 조류, 달팽이 및 기타 살아있는 동물 혹은 동물 제품. 이러한 품목을 세관에 신고하지 않으면 벌금이 초래될 수 있으며 해당 품목은 압류될 수 있습니다. 귀하는 괌 내부 세입 법률에 따라 해외에서 취득한 모든 물품, 즉, 오래되거나 사용한 제품, 과세 및 비과세 물품, 선물로 받은 물품에 상관없이 신고해야 합니다. 모든 해당 품목 혹은 상품을 기재해주시기 바랍니다.",
  bringingLabel: "다음에 해당하는 물품을 소지하고 괌에 입국합니다.",
  //         Person 2: "동반인 2 이름",
  // Person 3: "동반인 3 이름",
  // Person 4: "동반인 4 이름",
  // Person 5: "동반인 5 이름",
  // Person 6: "동반인 6 이름",
  // Person 7: "동반인 7 이름",
  // Person 8: "동반인 8 이름",
  FullNameText: "이름",
  visitorSpecificDescriptionLabel: "방문객 정보",
  lengthOfStayLabel: "체류 기간",
  tripDescription: "괌 방문 횟수",
  onlineBookingLabel: "이번 여행을 온라인으로 예약하셨나요? ",
  arrangementsLabel: "여행 예약 방법",
  placeOfStayLabel:
    "괌에 체류하는 동안 어디서 체류하실 예정인가요? (해당사항 모두 표시) ",
  stay: [
    "호텔",
    "게스트 하우스",
    "크루즈 선박",
    "친구 혹은 친척집",
    "베드 엔 브렉퍼스트",
    "군사 시설",
    "타임쉐어",
    "기타",
  ],
  tripReasonLabelText: "여행 목적",
  EmailCollectionText:
    "괌정부관광청으로부터 추후 진행되는 이벤트 및 프로모션 정보 수신을 원하시면 아래에 연락처와 이메일을 기재해 주시기 바랍니다.",
  emailLabel: "이메일",
  PhoneFieldLabel: "휴대폰 번호",
  status: "환승",
  lengthOfStayLabel: "체류 기간",
  transitingDestination: "최종 도착지",
  intendedResidentDescription: "괌 거주 예정자",
  residentFormDescription: "괌 귀환 거주자",
  returningReasonLabel: "여행 목적 작성 완료",
  declarationDescription:
    "물품신고서\n괌 세관 및 검역 본부는 금지된 품목의 불법 반입에 대해 괌을 보호할 책임이 있습니다. 세관원들은 귀하 및 귀하의 개인 소유물에 대해 질문하고 검사할 권한이 있습니다. 세관 검사 대상으로 선별된 경우 귀하는 정중하고 전문적인 방법으로 대우를 받을 것이며. 세관원 혹은 담당자는 귀하의 질문에 답을 할 수 있습니다.\n\n경고: 금지 물품을 괌으로 반입하는 것은 불법입니다. (GCA 9장 67조). 이 조항을 위반할 경우 10년 이하의 의무 징역 선고를 받게 되며 세관원에게 허위 진술을 할 경우 법에 의해 처벌을 받게 됩니다. 핸드백을 포함한 모든 수화물은 열어서 검사할 수 있습니다. 신고대상 품목은 세관원에게 문의하시기 바랍니다. 위험한 농업 해충 및 금지된 야생 생물의 반입을 금지하기 위해 다음과 같은 품목이 금지됩니다. 금지 품목: 과일, 채소, 식물, 식물 제품, 흙, 육류, 육류 제품, 조류, 달팽이 및 기타 살아있는 동물 혹은 동물 제품. 이러한 품목을 세관에 신고하지 않으면 벌금이 초래될 수 있으며 해당 품목은 압류될 수 있습니다. 귀하는 괌 내부 세입 법률에 따라 해외에서 취득한 모든 물품, 즉, 오래되거나 사용한 제품, 과세 및 비과세 물품, 선물로 받은 물품에 상관없이 신고해야 합니다. 모든 해당 품목 혹은 상품을 기재해주시기 바랍니다.",
  EveryFieldLabel: "이어서 응답해주시기 바랍니다. ",
  substancesLabel: "통제된 물질",
  firearmsLabel: "개인 총기류",
  explosivesLabel: "화약류/폭발물류 (폭죽) ",
  animalsLabel: "야생 동식물 및 야생 생물로부터 제조된 품목",
  animalProductsLabel: "육류, 우유, 혹은 알을 포함한 동물 제품",
  plantsLabel:
    "신선 과일 및 채소, 꽃씨 혹은 식물 재료로 만든 품목을 포함한 식물 혹은 식물 일부",
  soilLabel: "흙 물질 혹은 표본, 혹은 생물학 표본",
  serviceAnimalLabel: "보조 동물",
  cigarettesLabel:
    "다섯 보루/1000개비를 초과하는 담배 혹은 12갑을 초과하는 그 밖의 담배 제품",
  alcoholLabel: "성인 한 명당 US 1갤론/3.7리터를 초과하는 주류",
  Yes: "네",
  No: "아니요",
  declarationDescriptionLabel:
    "추가적으로 신고할 품목을 기재해 주시기 바랍니다.",
  declarationPreviousLabel:
    "귀하는 이전 페이지에서 아래 중 ‘네’를 선택하셨습니다. ",
  DeclarationNALabel:
    "귀하는 해당 금지 품목 중 어떠한 것도 소지하고 있지 않다고 답변하셨습니다. 추가적으로 신고할 품목이 있을 경우 아래에 작성해주시기 바랍니다.",
  GoodsDescriptionLabel: "품목 설명:",
  currencyVisitorCrewTitle: "통화",
  over10kLabel:
    "본인(우리)는 미화 10,000달러 이상 혹은 외화로 이와 동등한 가치의 통화나 화폐를 가지고 있습니다.",
  YesOrNoLabel: "네' 혹은 '아니요'로 답변해주시기 바랍니다.",
  resaleTitle: "상업적 상품",
  resaleLabel: "본인(우리)는 상업적 상품을 가지고 있습니다. (재판매 상품)",
  ResidentsTotalValueLabel:
    "거주자 – 해외에서 취득한 상업적 상품 (선물 포함)을 비롯하여 괌으로 반입하는 모든 물품의 총 가치 (US$)",
  remainLabel:
    "방문객 – 상업적 상품을 포함하여 괌에 남겨두게 될 모든 물품의 총 가치 (상업적 상품만 포함; 재판매) (US$)",
  EnterValueLabel: "값을 입력해 주시기 바랍니다.",
  consentDescriptionLabel:
    "## 진술서\n\n괌 세관 및 검역 본부는 금지된 품목의 불법 반입에 대해 괌을 보호할 책임이 있습니다. 세관원들은 귀하 및 귀하의 개인 소유물에 대해 질문하고 검사할 권한이 있습니다. 세관 검사 대상으로 선별된 경우 귀하는 정중하고 전문적인 방법으로 대우를 받을 것이며. 세관원 혹은 담당자는 귀하의 질문에 답을 할 수 있습니다. ",
  consentCertifyFieldLabel:
    "본인(나)는 이 신고서의 모든 사항을 읽고 이해했으며 본인이 수행한 모든 구두 및 서면 진술은 진실된 신고임에 동의합니다. ",
  healthFormDescription:
    "건강신고서\n\n괌 입국 시 모든 여행객은 반드시 건강신고서를 작성해야 합니다 (개별 당 신고서 각각 작성 필수). 영문으로 작성해주시기 바랍니다.",
  SeatNumberLabel: "좌석 번호",
  CountriesVisitedLabel:
    "지난 14일간 방문/환승한 국가를 모두 체크해주시기 바랍니다.",
  ChinaVisitedLabel: "중국",
  IranVisitedLabel: "이란",
  ItalyVisitedLabel: "이탈리아",
  SouthKoreaVisitedLabel: "대한민국",
  JapanVisitedLabel: "일본",
  HongKongVisitedLabel: "홍콩",
  MacaoSARVisitedLabel: "마카오",
  VietnamVisitedLabel: "베트남",
  ThailandVisitedLabel: "태국",
  PhilippinesVisitedLabel: "필리핀",
  MalaysiaVisitedLabel: "말레이시아",
  TaiwanVisitedLabel: "대만",
  CambodiaVisitedLabel: "캄보디아",
  SingaporeVisitedLabel: "싱가포르",
  NewZealandVisitedLabel: "뉴질랜드",
  AustraliaVisitedLabel: "호주",
  CanadaVisitedLabel: "캐나다",
  AmericanSamoaVisitedLabel: "미국령 사모아",
  MarshallIslandsVisitedLabel: "마셜 제도",
  FSMVisitedLabel: "미크로네시아 연방",
  CNMIVisitedLabel: "북마리아나 제도",
  PalauVisitedLabel: "팔라우",
  USVisitedLabel: "미국(시/주 명시)",
  OtherVisitedLabel: "기타(명시)",
  TelephoneNumber: "연락처",
  Email: "이메일",
  NationalityLabel: "국적",
  WorkplaceLabel: "직장",
  OccupationLabel: "직업",
  SymptomsLabel:
    "현재 혹은 지난 14일 간 아래 항목에 해당사항이 있을 시 체크해주시기 바랍니다.",
  FeverLabel: "열 (°F/C)",
  FeverAdviceText:
    "(열 증상 해당 시에만 작성 바랍니다. 지난 14일 간 열 증상이 없었을 경우 공란으로 남겨 두시기 바랍니다.)",
  Headache: "두통",
  Cough: "기침",
  SoreThroat: "인후염",
  BodyWeakness: "무력감",
  JointMusclePain: "근육통",
  BruisingBleeding: "타박상/출혈",
  Rashes: "발진",
  RedEyes: "충혈",
  Nausea: "메스꺼움",
  Vomiting: "구토",
  Diarrhea: "설사",
  DifficultybreathingShortnessofbreath: "호흡곤란",
  Other: "기타",
  healthWorkerLabel:
    "의료계 종사자, 병원, 진료소 및 요양원을 방문한 적 있습니까?",
  poultryFarmLabel: "양계장 혹은 가축 시장에 방문한 적 있습니까?",
  contactRespiratoryLabel:
    "본인 혹은 가족 구성원이 열, 기침, 호흡기 질환을 가진 사람을 가까이한 적이 있습니까?",
  sickFamilyGroupLabel:
    "가족 혹은 그룹 내에 다른 질병 증세를 보이는 사람이 있습니까?",
  recentMedicationLabel:
    "지난 4-6시간 내에 복용한 약물을 기재해 주시기 바랍니다.",
  dphssText:
    "괌 공중보건 및 사회복지국(DPHSS)은 괌 지역사회에 큰 위험이 되는 모든 건강 관련 위협으로부터 괌을 보호할 책임이 있습니다. 공중보건 비상사태 기간동안 검진관은 귀하의 이전 여행 기록 및 건강 정보와 관련된 질문을 할 수 있는 권한이 있습니다.\n\n허위 진술은 법률에 의거하여 처벌될 수 있습니다.\n\n본인(나)는 이 신고서의 모든 사항을 읽고 이해했으며 본인이 작성한 모든 내용이 사실이고 정확하다는 것을 괌의 위증죄 법률 아래 선언합니다. ",
  Person2: "### 동반인 2 이름",
  Person3: "### 동반인 3 이름",
  Person4: "### 동반인 4 이름",
  Person5: "### 동반인 5 이름",
  Person6: "### 동반인 6 이름",
  Person7: "### 동반인 7 이름",
  Person8: "### 동반인 8 이름",
  City: "출생도시",
  required: "필수 입력",
  acceptError: "🚫 계속 진행하시려면 동의 하셔야 합니다.",
  Male: "남",
  Female: "여",
  QRCode: "QR 코드",
  presentCode:
    "괌 전자 세관 신고서를 작성해 주셔서 감사합니다. 귀하의 고유 QR 코드를 출력/저장하여 괌 세관원에게 제출해주시기 바랍니다.",
  finish: "종료",
  thankYou: "시 주스 마아세 (감사합니다)",
  seeYouSoon: "괌에서 곧 뵙기를 바랍니다!",
  languages: {
    selectLanguage: "언어 선택",
    languagePrompt:
      "괌 EDF는 여러 언어로 제공되었습니다. 가장 편리한 언어를 선택하세요.",
    en: "영어 (English)",
    jp: "일본어 (日本語)",
    kr: "한국어",
    cn: "중국어 번체 (繁體中文)",
  },
  countries: {
    AF: "아프가니스탄",
    AX: "올란드 제도",
    AL: "알바니아",
    DZ: "알제리",
    AS: "아메리칸 사모아",
    AD: "안도라",
    AO: "앙골라",
    AI: "앵귈라",
    AQ: "남극",
    AG: "앤티가 바부다",
    AR: "아르헨티나",
    AM: "아르메니아",
    AW: "아루바",
    AU: "호주",
    AT: "오스트리아",
    AZ: "아제르바이잔",
    BS: "바하마",
    BH: "바레인",
    BD: "방글라데시",
    BB: "바베이도스",
    BY: "벨라루스",
    BE: "벨기에",
    BZ: "벨리즈",
    BJ: "베냉",
    BM: "버뮤다",
    BT: "부탄",
    BO: "볼리비아",
    BQ: "보네르, 신트외스타시우스, 사바",
    BA: "보스니아 헤르체고비나",
    BW: "보츠와나",
    BV: "부베섬",
    BR: "브라질",
    IO: "영국령 인도양 지역",
    BN: "브루나이",
    BG: "불가리아",
    BF: "부르키나파소",
    BI: "부룬디",
    CV: "카보베르데",
    KH: "캄보디아",
    CM: "카메룬",
    CA: "캐나다",
    KY: "케이맨 제도",
    CF: "중앙아프리카공화국",
    TD: "차드",
    CL: "칠레",
    CN: "중국",
    CX: "크리스마스 섬",
    CC: "코코스 제도",
    CO: "콜롬비아",
    KM: "코모로",
    CG: "콩고",
    CD: "콩고민주공화국",
    CK: "쿡제도",
    CR: "코스타리카",
    CI: "코트디부아르",
    HR: "크로아티아",
    CU: "쿠바",
    CW: "쿠라사우 섬",
    CY: "키프로스",
    CZ: "체코",
    DK: "덴마크",
    DJ: "지부티",
    DM: "도미니카",
    DO: "도미니카 공화국",
    EC: "에콰도르",
    EG: "이집트",
    SV: "엘살바도르",
    GQ: "적도 기니",
    ER: "에리트레아",
    EE: "에스토니아",
    SZ: "에스와티니",
    ET: "에티오피아",
    FK: "포클랜드 제도",
    FO: "패로제도",
    FJ: "피지",
    FI: "핀란드",
    FR: "프랑스",
    GF: "프랑스령 기아나",
    PF: "프랑스령 폴리네시아",
    TF: "프랑스령 남부",
    GA: "가봉",
    GM: "감비아",
    GE: "조지아",
    DE: "독일",
    GH: "가나",
    GI: "지브롤터",
    GR: "그리스",
    GL: "그린란드",
    GD: "그레나다",
    GP: "과들루프",
    GU: "괌",
    GT: "과테말라",
    GG: "건지",
    GN: "기니",
    GW: "기니비사우",
    GY: "가이아나",
    HT: "아이티",
    HM: "허드 맥도널드 제도",
    VA: "교황청",
    HN: "온두라스",
    HK: "홍콩",
    HU: "헝가리",
    IS: "아이슬란드",
    IN: "인도",
    ID: "인도네시아",
    IR: "이란",
    IQ: "이라크",
    IE: "아일랜드",
    IM: "맨섬",
    IL: "이스라엘",
    IT: "이탈리아",
    JM: "자메이카",
    JP: "일본",
    JE: "저지섬",
    JO: "요르단",
    KZ: "카자흐스탄",
    KE: "케냐",
    KI: "키리바시",
    KP: "북한",
    KR: "대한민국",
    KW: "쿠웨이트",
    KG: "키르기스스탄",
    LA: "라오스",
    LV: "라트비아",
    LB: "레바논",
    LS: "레소토",
    LR: "라이베리아",
    LY: "리비아",
    LI: "리히텐슈타인",
    LT: "리투아니아",
    LU: "룩셈부르크",
    MO: "마카오",
    MK: "마케도니아",
    MG: "마다가스카르",
    MW: "말라위",
    MY: "말레이시아",
    MV: "몰디브",
    ML: "말리",
    MT: "몰타",
    MH: "마셜제도",
    MQ: "마르티니크",
    MR: "모리타니",
    MU: "모리셔스",
    YT: "마요트섬",
    MX: "멕시코",
    FM: "미크로네시아 연방",
    MD: "몰도바",
    MC: "모나코",
    MN: "몽골",
    ME: "몬테네그로",
    MS: "몬세라트",
    MA: "모로코",
    MZ: "모잠비크",
    MM: "미얀마",
    NA: "나미비아",
    NR: "나우루",
    NP: "네팔",
    NL: "네덜란드",
    NC: "뉴칼레도니아",
    NZ: "뉴질랜드",
    NI: "니카라과",
    NE: "니제르",
    NG: "나이지리아",
    NU: "니우에 섬",
    NF: "노퍽 섬",
    MP: "북마리아나 제도",
    NO: "노르웨이",
    OM: "오만",
    PK: "파키스탄",
    PW: "팔라우",
    PS: "팔레스타인",
    PA: "파나마",
    PG: "파푸아뉴기니",
    PY: "파라과이",
    PE: "페루",
    PH: "필리핀",
    PN: "핏케언 제도",
    PL: "폴란드",
    PT: "포르투갈",
    PR: "푸에르토리코",
    QA: "카타르",
    RE: "레위니옹",
    RO: "루마니아",
    RU: "러시아",
    RW: "르완다",
    BL: "생바르텔레미",
    SH: "세인트헬레나, 어센션, 트리스탄다쿠냐",
    KN: "세인트키츠 네비스",
    LC: "세인트루시아",
    MF: "세인트마틴 섬(프랑스 령)",
    PM: "생피에르 미클롱",
    VC: "세인트빈센트 그레나딘",
    WS: "사모아",
    SM: "산마리노",
    ST: "상투메 프린시페",
    SA: "사우디아라비아",
    SN: "세네갈",
    RS: "세르비아",
    SC: "세이셸",
    SL: "시에라리온",
    SG: "싱가포르",
    SX: "신트마르턴(네덜란드 령)",
    SK: "슬로바키아",
    SI: "슬로베니아",
    SB: "솔로몬제도",
    SO: "소말리아",
    ZA: "남아프리카공화국",
    GS: "사우스조지아 사우스샌드위치 제도",
    SS: "남수단",
    ES: "스페인",
    LK: "스리랑카",
    SD: "수단",
    SR: "수리남",
    SJ: "스발바르와 얀마옌 제도",
    SE: "스웨덴",
    CH: "스위스",
    SY: "시리아",
    TW: "타이완, 대만",
    TJ: "타지키스탄",
    TZ: "탄자니아",
    TH: "태국",
    TL: "동티모르",
    TG: "토고",
    TK: "토켈라우",
    TO: "통가",
    TT: "트리니다드 토바고",
    TN: "튀니지",
    TR: "터키",
    TM: "투르크메니스탄",
    TC: "터크스 케이커스 제도",
    TV: "투발루",
    UG: "우간다",
    UA: "우크라이나",
    AE: "아랍에미리트",
    GB: "영국",
    US: "미국",
    UM: "미국령 군소 제도",
    UY: "우루과이",
    UZ: "우즈베키스탄",
    VU: "바누아투",
    VE: "베네수엘라",
    VN: "베트남",
    VG: "버진아일랜드 (영국령)",
    VI: "버진아일랜드 (미국령)",
    WF: "왈리스 푸투나",
    EH: "서사하라",
    YE: "예멘",
    ZM: "잠비아",
    ZW: "짐바브웨",
  },
  wantEmail:
    "본 QR코드의 사본을 귀하의 이메일로 전송하려면 아래에 이메일 주소를 입력하세요.",
  keepOpen:
    "이메일 전송을 원치 않으실 경우, 현재 페이지를 닫지말고 열어두시기 바랍니다.",
  showCode:
    "괌 전자 세관신고서를 작성해 주셔서 감사합니다. 아래의 QR 코드를 괌 세관원에게 제시해주시기 바랍니다.",
  QREmailSubject: "괌 입국 용 QR 코드",
  hasErrors: "양식을 작성했는지 확인하십시오",
  NonLatinCharacters: "다음 문자는 영어가 아닙니다 : ",
  errorOccured: "에러가 발생했습니다. 다시 시도 해주세요.",
  emailSent: "이메일이 성공적으로 전송되었습니다.",
};
export default kr_strings;
