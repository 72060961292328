import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";
import moment from "moment";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

import {
  getValues,
  loadValues,
  resetValues,
  resetForm,
  saveValues,
  getQRBase,
  getName,
} from "./session";
import sha256 from "./sha256";

import Save from "./save";

const submitButton = compiler.newComponent({
  name: "SubmitButton",
  label: "{{{nextLabel}}}", //TODO
  type: "button",
  component: "ButtonField",
  block: false,
});
const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  block: false,
});

const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  // block: true
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});
const codeField = compiler.newComponent({
  name: "QRCodeBase",
  component: "LatinOnlyTextField",
  hidden: true,
});
const partyIDField = compiler.newComponent({
  name: "PartyID",
  component: "LatinOnlyTextField",
  hidden: true,
});

const consent = {
  component: "Form",
  name: "app.ConsentForm",
  fields: [
    {
      component: "Text",
      text: "{{{heading}}}{{{consentDescriptionLabel}}}",
      name: "DescriptionField",
    },
    {
      component: "SingleCheckboxField",
      name: "ConsentField",
      label: "{{{consentCertifyFieldLabel}}}",
      required: true,
      block: true,
    },
    previousButton,
    submitButton,
    resetButton,
    {
      component: "LatinOnlyTextField",
      hidden: true,
      disabled: true,
      value: "{{{acceptError}}}",
      label: "{{{acceptError}}}", //TODO
      fullWidth: true,
      name: "ErrorText",
    },
    codeField,
    partyIDField,
  ],
};

const form = compiler.newComponent(consent);

const submit = async () => {
  // form.setDirty(false);
  form.getField("QRCodeBase").setValue(getCodeValues());
  form.getField("PartyID").setValue(getPartyID());
  saveValues(form);
  //globals.redirect(NextDestination(form));

  await Save();
  globals.redirect(`/code/${getQRBase()}/${getName()}`);
};
form["submit"] = () => {
  return false;
};

submitButton.on("click", () => {
  form.getField("ErrorText")._set("hidden", true);
  const fields = ["ConsentField"];
  const check = fields.filter((field) => {
    return !form.getField(field)._value;
  });
  if (check.length === 0) {
    form.getField("ErrorText")._set("hidden", true);
    submit();
  } else {
    form.getField("ErrorText")._set("hidden", false);
  }
});
form.on("load", (f) => {
  loadValues(form);
});
form.on("reset", (f) => {
  resetValues(form);
});

previousButton.on("click", () => {
  form.setDirty(false);
  history.back();
});

const getCodeValues = () => {
  let formValues = getValues({ _name: "app.CustomerForm" });
  const dateValue = moment
    .utc(formValues["ArrivalDate"])
    .tz("Pacific/Guam")
    .startOf("day")
    .toISOString();
  const nowValue = moment.now();
  const baseString = `${nowValue};${dateValue};${formValues["DOBDayField"]}-${formValues["DOBMonthField"]}-${formValues["DOBYearField"]};-${formValues["PassportNoField"]};${formValues["PassportCountryField"]}`;
  return sha256(baseString);
};

const getPartyID = () => {
  let formValues = getValues({ _name: "app.CustomerForm" });
  const dateValue = moment
    .utc(formValues["ArrivalDate"])
    .tz("Pacific/Guam")
    .startOf("day")
    .toISOString();
  const baseString = `${dateValue};${formValues["DOBDayField"]}-${formValues["DOBMonthField"]}-${formValues["DOBYearField"]};-${formValues["PassportNoField"]};${formValues["PassportCountryField"]}`;
  return sha256(baseString);
};

export { form as consentForm };
