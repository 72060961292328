// import React from 'react';
// import Checkbox from '@material-ui/core/Checkbox';
// import Component from 'mson/lib/component';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import CommonField from "../mson-react-bootstrap/fields/common-field";
import attach from "../mson-react-bootstrap/attach";
import DisplayValueTypography from "../mson-react-bootstrap/fields/display-value-typography";

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { LangContext } from "../lib/LangContext";

const langMap = {
  en: "English",
  kr: "Korean",
  jp: "Japanese",
  cn: "Chinese",
};

class LanguageField extends React.PureComponent {
  static contextType = LangContext;

  componentDidMount = () => {
    const { lang } = this.context;
    this.props.component.setValue(langMap[lang]);
  };

  render() {
    return null;
  }
}
const returnValue = attach([
  "value",
  "err",
  "disabled",
  "required",
  "editable",
  "useDisplayValue",
  "label",
])(LanguageField);

export default returnValue;
