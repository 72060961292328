const FullNameFields = [
  {
    name: "FullNameLabel",
    text: "{{{nameFieldLabel}}}",
    component: "Text",
  },
  {
    name: "FirstNameField",
    label: "{{{firstNameLabel}}}",
    component: "LatinOnlyTextField",
    block: false,
    required: true,
  },
  {
    name: "MiddleNameField",
    label: "{{{middleNameLabel}}}",
    component: "LatinOnlyTextField",
    block: false,
    required: false,
  },
  {
    name: "LastNameField",
    label: "{{{lastNameLabel}}}",
    component: "LatinOnlyTextField",
    block: true,
    required: true,
  },
];
export default FullNameFields;
