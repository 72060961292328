import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

import {
  getStatus,
  loadValues,
  saveValues,
  resetValues,
  resetForm,
  getPackingList,
} from "./session";

const submitButton = compiler.newComponent({
  name: "SubmitButton",
  label: "{{{nextLabel}}}", //TODO
  type: "button",
  component: "ButtonField",
  block: false,
});
const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  block: false,
});

const remainString = () => {
  let returnString = "{{{remainLabel}}}";
  if (!getStatus()) return returnString;
  if (getStatus().includes("resident"))
    returnString = "{{{ResidentsTotalValueLabel}}}";
  // "RESIDENTS - The total value of all goods, including commercial merchandise acquired abroad, including gifts brought into Guam is: ($)";
  return returnString;
};

const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  // block: true
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});
const TenKField = {
  name: "10KField",
  label: "{{{over10kLabel}}}",
  block: true,
  // required: true,
  component: "CheckboxField",
};
const ResaleField = compiler.newComponent({
  name: "ResaleField",
  component: "BooleanField",
  label: "{{{resaleLabel}}}",
  value: false,
});
const form = compiler.newComponent({
  component: "Form",
  name: "app.CurrencyVisitorCrewForm",
  fields: [
    {
      component: "Text",
      name: "DescriptionField",
      text: "{{{heading}}}{{{currencyVisitorCrewTitle}}}",
    },
    TenKField,
    // {
    //     name: "RemainField",
    //     // label: remainString(),
    //     label: 'Total Value (US $)',
    //     component: "NumberField",
    //     fullWidth: true,
    //     hidden: true
    // },
    previousButton,
    submitButton,
    resetButton,
    {
      component: "LatinOnlyTextField",
      hidden: true,
      disabled: true,
      value: "🚫 {{{YesOrNoLabel}}}",
      label: "🚫 {{{YesOrNoLabel}}}",
      fullWidth: true,
      name: "ErrorText",
    },
  ],
});

form["submit"] = () => {
  return false;
};
submitButton.on("click", () => {
  form.getField("ErrorText")._set("hidden", true);
  const fields = ["10KField"];
  const check = fields.filter((field) => {
    if (form.getField(field)._value === null) {
      return true;
    }
  });
  if (check.length === 0) {
    form.getField("ErrorText")._set("hidden", true);
    submit();
  } else {
    form.getField("ErrorText")._set("hidden", false);
  }
});
const submit = () => {
  saveValues(form);
  form.setDirty(false);
  let tenKValue = form.getField("10KField")._value;
  // let resaleValue = ResaleField.getValue();
  let packingListItems = getPackingList();
  if (packingListItems?.length === 0) packingListItems = "null";
  globals.redirect("/resale");
  // location.href = URL;
};

previousButton.on("click", () => {
  form.setDirty(false);
  history.back();
});

form.on("load", (f) => {
  loadValues(form);
  // form.getField('RemainField')._set('hidden',!form.getField('10KField')._value);
});
form.on("reset", (f) => {
  resetValues(form);
});
export { form as currencyVisitorCrew };
