import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

import { options } from "./options";
import { loadValues, saveValues, resetValues, resetForm } from "./session";
import { TripReason } from "./tripReason";
import { NextDestination, PreviousDestination } from "./destinations";

const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  block: false,
});

const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  // block: true
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});

const form = compiler.newComponent({
  component: "Form",
  name: "app.ResidentForm",
  fields: [
    {
      name: "DescriptionField",
      text: "{{{heading}}}{{{residentFormDescription}}}",
      component: "Text",
    },
    {
      component: "SelectField",
      name: "ReturningReason",
      fullWidth: true,
      label: "{{{returningReasonLabel}}}",
      options: options.reasonOptions,
      autocomplete: false,
    },
    previousButton,
    {
      name: "SubmitButton",
      label: "{{{nextLabel}}}", //TODO
      type: "submit",
      component: "ButtonField",
      block: false,
    },
    resetButton,
  ],
});

previousButton.on("click", () => {
  form.setDirty(false);
  history.back();
  // globals.redirect(PreviousDestination(form));
});

form["submit"] = () => {
  saveValues(form);
  form.setDirty(false);
  globals.redirect(NextDestination(form));
};

form.on("load", (f) => {
  loadValues(form);
});
form.on("reset", (f) => {
  resetValues(form);
});

export { form as resident };
