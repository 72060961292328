import compiler from "mson/lib/compiler/compiler";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

import {
  getValue,
  loadValues,
  resetValues,
  resetForm,
  saveValues,
  getQRBase,
  getFamilyValue,
  getName,
  saveAllValues,
} from "./session";
import globals from "mson/lib/globals";
import Save from "./save";

const addressField = compiler.newComponent({
  name: "AddressOnGuam",
  label: "{{{addressOnGuamLabel}}}",
  component: "LatinOnlyTextField",
  fullWidth: true,
  hidden: true,
});
const statusField = compiler.newComponent({
  name: "StatusField",
  component: "LatinOnlyTextField",
  label: "status",
  required: true,
  value: "placeholder",
  hidden: true,
});
const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  // block: true
});
const EmailField = compiler.newComponent({
  component: "EmailField",
  name: "EmailField",
  label: "{{{emailLabel}}}",
  block: true,
});
resetButton.on("click", () => {
  health._events.reset();
  resetForm(health);
});
const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  // block: true
});
previousButton.on("click", () => {
  history.back();
  // let formNumber = Number(health.getField("FormNumber")._value);
  // if(formNumber !== 1) {
  //     globals.redirect(`/health/${formNumber - 1}`);
  // } else {
  //     globals.redirect("/consent-form");
  // }
});

const form = {
  component: "Form",
  name: "app.HealthForm",
  fields: [
    {
      name: "DOBDayField",
      component: "LatinOnlyTextField",
      hidden: true,
      label: "",
      value: "",
    },
    {
      name: "DOBMonthField",
      component: "LatinOnlyTextField",
      hidden: true,
      label: "",
      value: "",
    },
    {
      name: "DOBYearField",
      component: "LatinOnlyTextField",
      hidden: true,
      label: "",
      value: "",
    },
    {
      name: "FirstNameField",
      component: "LatinOnlyTextField",
      hidden: true,
      label: "",
      value: "",
    },
    {
      name: "MiddleNameField",
      component: "LatinOnlyTextField",
      hidden: true,
      label: "",
      value: "",
    },
    {
      name: "LastNameField",
      component: "LatinOnlyTextField",
      hidden: true,
      label: "",
      value: "",
    },
    {
      name: "PassportNoField",
      component: "LatinOnlyTextField",
      hidden: true,
      label: "",
      value: "",
    },
    {
      name: "PassportCountryField",
      component: "LatinOnlyTextField",
      hidden: true,
      label: "",
      value: "",
    },
    {
      name: "DescriptionField",
      component: "Text",
      text: "{{{healthFormDescription}}}",
    },
    {
      component: "LatinOnlyTextField",
      name: "FormNumber",
      value: "",
      hidden: true,
    },
    {
      component: "LatinOnlyTextField",
      name: "FormCounter",
      value: "",
      fullWidth: true,
      disabled: true,
    },
    {
      name: "SeatNumberField",
      label: "{{{SeatNumberLabel}}}",
      component: "LatinOnlyTextField",
      required: true,
      block: true,
    },
    statusField,
    {
      name: "CountriesVisited",
      text: "{{{CountriesVisitedLabel}}}",
      component: "Text",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{ChinaVisitedLabel}}}",
      name: "VisitedChina",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{IranVisitedLabel}}}",
      name: "VisitedIran",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{ItalyVisitedLabel}}}",
      name: "VisitedItaly",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{SouthKoreaVisitedLabel}}}",
      name: "VisitedSouthKorea",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{JapanVisitedLabel}}}",
      name: "VisitedJapan",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{HongKongVisitedLabel}}}",
      name: "VisitedHongKong",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{MacaoSARVisitedLabel}}}",
      name: "VisitedMacaoSAR",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{VietnamVisitedLabel}}}",
      name: "VisitedVietnam",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{ThailandVisitedLabel}}}",
      name: "VisitedThailand",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{PhilippinesVisitedLabel}}}",
      name: "VisitedPhilippines",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{MalaysiaVisitedLabel}}}",
      name: "VisitedMalaysia",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{TaiwanVisitedLabel}}}",
      name: "VisitedTaiwan",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{CambodiaVisitedLabel}}}",
      name: "VisitedCambodia",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{SingaporeVisitedLabel}}}",
      name: "VisitedSingapore",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{NewZealandVisitedLabel}}}",
      name: "VisitedNewZealand",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{AustraliaVisitedLabel}}}",
      name: "VisitedAustralia",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{CanadaVisitedLabel}}}",
      name: "VisitedCanada",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{AmericanSamoaVisitedLabel}}}",
      name: "VisitedAmericanSamoa",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{MarshallIslandsVisitedLabel}}}",
      name: "VisitedMarshallIslands",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{FSMVisitedLabel}}}",
      name: "VisitedFSM",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{CNMIVisitedLabel}}}",
      name: "VisitedCNMI",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{PalauVisitedLabel}}}",
      name: "VisitedPalau",
    },
    {
      block: false,
      component: "LatinOnlyTextField",
      value: "false",
      label: "{{{USVisitedLabel}}})",
      name: "VisitedUS",
    },
    {
      block: true,
      component: "LatinOnlyTextField",
      value: "false",
      label: "{{{OtherVisitedLabel}}}",
      name: "VisitedOther",
    },

    addressField,
    {
      name: "Telephone",
      label: "{{{TelephoneNumber}}}",
      // label: "Telephone Number",
      component: "LatinOnlyTextField",
      required: false,
      block: false,
    },
    EmailField,
    {
      name: "Nationality",
      label: "{{{NationalityLabel}}}",
      component: "TranslatedCountryField",
      fullWidth: true,
      required: true,
    },
    {
      name: "WorkPlace",
      label: "{{{WorkplaceLabel}}}",
      component: "LatinOnlyTextField",
      block: true,
    },
    {
      name: "Occupation",
      label: "{{{OccupationLabel}}}",
      component: "LatinOnlyTextField",
      block: true,
    },
    {
      name: "SymptomsDescription",
      component: "Text",
      text: "{{{SymptomsLabel}}}",
      // text: "Please check if you have any of the following at present or during the past 14 days:"
    },
    { component: "Text", text: "{{{FeverAdviceText}}}" },
    // { component: "Text", text: "(please only enter a fever if applicable. If you have not had a fever in the past 14 days leave this field blank)"},
    {
      component: "LatinOnlyTextField",
      value: "(F/C)",
      label: "{{{FeverLabel}}}",
      name: "Fever",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{Headache}}}",
      name: "Headache",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{Cough}}}",
      name: "Cough",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{SoreThroat}}}",
      name: "SoreThroat",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{BodyWeakness}}}",
      name: "BodyWeakness",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{JointMusclePain}}}",
      name: "JointMusclePain",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{BruisingBleeding}}}",
      name: "BruisingBleeding",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{Rashes}}}",
      name: "Rashes",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{RedEyes}}}",
      name: "RedEyes",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{Nausea}}}",
      name: "Nausea",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{Vomiting}}}",
      name: "Vomiting",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{Diarrhea}}}",
      name: "Diarrhea",
    },
    {
      block: false,
      component: "BooleanField",
      value: "false",
      label: "{{{DifficultybreathingShortnessofbreath}}}",
      name: "DifficultybreathingShortnessofbreath",
    },
    {
      component: "LatinOnlyTextField",
      value: "",
      label: "{{{Other}}}",
      name: "Other",
    },
    { component: "Text", text: "\n***\n" },
    {
      component: "BooleanField",
      value: "false",
      name: "healthWorker",
      label: "{{{healthWorkerLabel}}}",
    },
    // { component: "BooleanField", value: "false", name: "healthWorker", label: "DID YOU VISIT ANY HEALTH WORKER, HOSPITAL, CLINIC OR NURSING HOME?"  },
    {
      component: "BooleanField",
      value: "false",
      name: "poultryFarm",
      label: "{{{poultryFarmLabel}}}",
    },
    // { component: "BooleanField", value: "false", name: "poultryFarm", label: "DID YOU VISIT ANY POULTRY FARM OR ANIMAL MARKET?"  },
    {
      component: "BooleanField",
      value: "false",
      name: "contactRespiratory",
      label: "{{{contactRespiratoryLabel}}}",
    },
    // { component: "BooleanField", value: "false", name: "contactRespiratory", label: "HAVE YOU OR ANY OF YOUR FAMILY MEMBERS BEEN IN CLOSE CONTACT WITH A PERSON WHO HAS A FEVER, COUGH AND/OR RESPIRATORY PROBLEMS?" },
    {
      component: "BooleanField",
      value: "false",
      name: "sickFamilyGroup",
      label: "{{{sickFamilyGroupLabel}}}",
    },
    // { component: "BooleanField", value: "false", name: "sickFamilyGroup", label: "ANY OTHERS SICK WITHIN YOUR FAMILY/GROUP?" },
    {
      component: "LatinOnlyTextField",
      label: "{{{recentMedicationLabel}}}",
      name: "recentMedication",
      fullWidth: true,
    },
    // { component: "LatinOnlyTextField", label: "LIST ANY MEDICATION TAKEN IN THE LAST 4-6 HRS:", name: "recentMedication", fullWidth: true  },
    { component: "Text", text: "{{{dphssText}}}" },
    // { component: "Text", text: "The Department of Public Health and Social Services’ (DPHSS) mission is to protect Guam against all health-related threats that pose the greatest risk to our island community. During a Public Health Emergency, health screeners are authorized to ask you questions involving prior travel history and health information.\n\nFALSE STATEMENTS ARE PUNISHABLE BY LAW.\n\nI DECLARE UNDER PENALTY OF PERJURY UNDER THE LAWS OF GUAM THAT I HAVE READ AND UNDERSTOOD THE REQUIREMENTS OF THIS FORM AND THAT ALL WRITTEN STATEMENTS THAT I HAVE MADE ARE TRUE AND CORRECT" },
    previousButton,
    {
      name: "SubmitButton",
      label: "{{{nextLabel}}}", //TODO
      type: "submit",
      component: "ButtonField",
      block: false,
    },
    resetButton,
  ],
};

const health = compiler.newComponent(form);

health["submit"] = async () => {
  saveValues(health);
  health.setDirty(false);
  let formNumber = Number(health.getField("FormNumber")._value);
  const totalForms = getFamilyValue();
  if (formNumber < totalForms) {
    globals.redirect(`/health/${formNumber + 1}`);
    loadForm();
  } else {
    await Save();
    globals.redirect(`/code/${getQRBase()}/${getName()}`);
  }
};

const loadForm = () => {
  const addressValue = getValue("app.CustomerForm", "AddressOnGuam");
  addressField._set("value", addressValue);
  const statusValue = getValue("app.CustomerForm", "StatusField");
  statusField._set("value", statusValue);
  const formNumber = globals._route.parameters["number"];
  let seriesCount = formNumber - 1;
  if (seriesCount === 0) seriesCount = "";
  const loadFields = [
    "DOBDayField",
    "DOBMonthField",
    "DOBYearField",
    "FirstNameField",
    "LastNameField",
    "MiddleNameField",
    "PassportCountryField",
    "PassportNoField",
  ];
  loadFields.forEach((field) => {
    const loadFrom = `${field}${seriesCount}`;
    const formValue = getValue("app.CustomerForm", loadFrom);
    if (formValue) health.getField(field).setValue(formValue);
  });

  health.getField("FormNumber").setValue(formNumber);
  const dobValue = `${health.getField("DOBDayField").getValue()}-${health
    .getField("DOBMonthField")
    .getValue()}-${health.getField("DOBYearField").getValue()}`;
  // const counterValue = "foo";
  const middle = health.getField("MiddleNameField").getValue()
    ? ` ${health.getField("MiddleNameField").getValue()} `
    : " ";
  // return `${formValues.FirstNameField} ${middle}${formValues.LastNameField}`
  const counterValue = `${health
    .getField("FirstNameField")
    .getValue()}${middle}${health.getField("LastNameField").getValue()}/${health
    .getField("PassportNoField")
    .getValue()}/${dobValue}`;
  health.getField("FormCounter").setValue(counterValue);
  health._name = `app.HealthForm${formNumber}`;
  loadValues(health);
};

health.on("load", loadForm);
health.on("reset", (f) => {
  resetValues(health);
});

export default health;
