import { getValues, getPackingList } from "./session";

const packingStrings = {
  AlcoholField: "{{{alcoholLabel}}}",
  AnimalProductsField: "{{{animalProductsLabel}}}",
  AnimalsField: "{{{animalsLabel}}}",
  CigarettesField: "{{{cigarettesLabel}}}",
  ExcisableGoodsField: "{{{excisableGoodsLabel}}}",
  ExplosivesField: "{{{explosivesLabel}}}",
  FirearmsField: "{{{firearmsLabel}}}",
  PersonalGoodsField: "{{{personalGoodsLabel}}}",
  PlantsField: "{{{plantsLabel}}}",
  ServiceAnimalField: "{{{serviceAnimalLabel}}}",
  SoilField: "{{{soilLabel}}}",
  SubstancesField: "{{{substancesLabel}}}",
};
const buildDeclaration = () => {
  let declarationText = "\n";
  let pathText = "null/";
  let packingValue = false;
  let describeLabel = "{{{declarationDescriptionLabel}}}";
  // let describeLabel = "{{{heading}}}{{{declarationDescriptionLabel}}}";
  const packingList = getPackingList();

  if (packingList) {
    packingList.forEach((item) => {
      if (packingStrings[item]) {
        packingValue = true;
        declarationText =
          declarationText + "\n * " + packingStrings[item] + "\n";
      }
    });
  }

  if (declarationText === "\n") {
    declarationText = "\nN/A";
    describeLabel = "{{{DeclarationNALabel}}}";
    // describeLabel = "You have indicated that you are NOT bringing any of the listed goods into Guam. Please list below any additional goods that you would like to declare."
  }
  return {
    declaration: declarationText,
    path: pathText,
    packing: packingValue,
    describeLabel: describeLabel,
  };
};
export default buildDeclaration;
