import { useContext, useEffect, useState } from "react";
import { LangContext } from "../lib/LangContext";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { koKR, jaJP, enUS, zhCN } from "@material-ui/core/locale";

const langMap = {
  kr: koKR,
  jp: jaJP,
  cn: zhCN,
  en: enUS,
};

export const MuiThemeProviderTranslated = ({ theme, children }) => {
  const { lang, setLang } = useContext(LangContext);

  return (
    <MuiThemeProvider theme={{ ...theme, ...langMap[lang] }}>
      {children}
    </MuiThemeProvider>
  );
};
