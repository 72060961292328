import React, { useEffect, useState } from "react";
import { render } from "mustache";
import { getStrings } from "./strings";
import { getUserLocale } from "get-user-locale";

const langs = {
  en: true,
  kr: true,
  jp: true,
  cn: true,
};

// const LangContext = React.createContext();
export const LangContext = React.createContext(
  // default values used by a Consumer when it does not have a
  // matching Provider above it in the tree, useful for testing
  {
    lang: "en",
    setLang: () => {},
  }
);

export const LangProvider = (props) => {
  const [lang, setLang] = useState("en");
  const [strings, setStrings] = useState(getStrings(lang));
  useEffect(() => {
    setStrings(getStrings(lang));
  }, [lang]);

  useEffect(() => {
    const locale = getUserLocale();
    const [lang, country] = locale.split("-");
    if (langs[lang]) {
      setLang(lang);
    } else {
      setLang("en");
    }
  }, []);

  const translate = (str) => render(str, strings);

  return (
    <LangContext.Provider
      value={{
        lang,
        setLang,
        translate,
      }}
    >
      {props.children}
    </LangContext.Provider>
  );
};
