import React, { useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Logo from "../customs.png";
import { LangContext } from "../lib/LangContext";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { startOver } from "../lib/session";
import globals from "mson/lib/globals";

const styles = (theme) => ({
  form: {
    margin: "auto",
    textAlign: "center",
  },
});

export const Reset = withStyles(styles, { withTheme: true })(
  ({ classes, pathRoot = "/", className }) => {
    const { push } = useHistory();

    useEffect(() => {
      setTimeout(function () {
        startOver();
        globals.redirect(pathRoot);
        push(pathRoot);
      }, 10000);
    }, []);
    const { translate } = useContext(LangContext);
    return (
      <main className={className}>
        <div className={classnames(classes.form, "form")}>
          <h3>{translate("{{thankYou}}")}</h3>
          <p>{translate("{{seeYouSoon}}")}</p>
          <img src={Logo} />
        </div>
      </main>
    );
  }
);
