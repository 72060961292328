import React from "react";
import ReactDOM from "react-dom";
import Modal from "./modal";
import compiler from "mson/lib/compiler/compiler";
import globals from "mson/lib/globals";
import { startOver } from "./session";

const continueButton = {
  name: "ContinueButton",
  label: "{{{welcomeButtonText}}}",
  type: "button",
  component: "ButtonField",
  block: false,
};

const welcomeJson = {
  component: "Form",
  name: "app.WelcomeForm",
  fields: [
    {
      component: "Text",
      name: "WelcomeText",
      text: "{{{heading}}}{{{welcomeText}}}",
    },
    continueButton,
  ],
  listeners: [
    {
      event: "ContinueButton",
      actions: [
        {
          component: "Redirect",
          path: "/form/true",
        },
      ],
    },
  ],
};

const welcome = compiler.newComponent(welcomeJson);

export { welcome };
