import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

import {
  getStatus,
  loadValues,
  saveValues,
  resetValues,
  resetForm,
} from "./session";

const submitButton = compiler.newComponent({
  name: "SubmitButton",
  label: "{{{nextLabel}}}", //TODO
  type: "button",
  component: "ButtonField",
  block: false,
});
const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  block: false,
});

const remainString = () => {
  let returnString = "{{{remainLabel}}}";
  if (!getStatus()) return returnString;
  if (getStatus().includes("resident"))
    returnString = "{{{ResidentsTotalValueLabel}}}"; //TODO
  return returnString;
};

const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  // block: true
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});
const Resalefield = compiler.newComponent({
  name: "ResaleField",
  label: "{{{resaleLabel}}}",
  component: "CheckboxField",
  required: true,
});

Resalefield.on("$change", (event) => {
  if (event !== "value") return;
  if (
    form.getField("ResaleField")._value &&
    form.getField("ResaleField")._value === true
  ) {
    form.getField("RemainField")._set("hidden", false);
    form.getField("RemainField")._set("required", true);
  } else {
    form.getField("RemainField")._set("hidden", true);
    form.getField("RemainField")._set("required", false);
  }
});
const RemainField = compiler.newComponent({
  name: "RemainField",
  label: remainString(),
  // label: 'Total Value (US $)',
  component: "NumberField",
  fullWidth: true,
  // required: true,
  // hidden: true,
});
const errorField = compiler.newComponent({
  component: "LatinOnlyTextField",
  hidden: true,
  disabled: true,
  value: "",
  label: "",
  fullWidth: true,
  name: "ErrorText",
});
const form = compiler.newComponent({
  component: "Form",
  name: "app.ResaleForm",
  fields: [
    {
      component: "Text",
      name: "DescriptionField",
      text: "{{{heading}}}{{{resaleTitle}}}",
    },
    Resalefield,
    RemainField,
    previousButton,
    submitButton,
    resetButton,
    errorField,
  ],
});

submitButton.on("click", () => {
  if (form.getField("ResaleField")._value === null) {
    form.getField("ErrorText")._set("value", "🚫 {{{YesOrNoLabel}}}");
    form.getField("ErrorText")._set("hidden", false);
  } else {
    if (form.getField("ResaleField")._value === true) {
      if (
        form.getField("RemainField")._value === "" ||
        !form.getField("RemainField")._value
      ) {
        form.getField("ErrorText")._set("value", "🚫 {{{EnterValueLabel}}}");
        form.getField("ErrorText")._set("hidden", false);
      } else {
        form.submit();
      }
    } else {
      form.submit();
    }
  }
});
form["submit"] = () => {
  saveValues(form);
  form.setDirty(false);
  // return;
  globals.redirect("/consent-form");
};

previousButton.on("click", () => {
  form.setDirty(false);
  history.back();
});

form.on("load", (f) => {
  loadValues(form);
  form
    .getField("RemainField")
    ._set("required", form.getField("ResaleField")._value);
  // form.getField('RemainField')._set('hidden',!form.getField('ResaleField')._value);
  form.getField("ErrorText")._set("value", "");
  form.getField("ErrorText")._set("hidden", true);
});
form.on("reset", (f) => {
  resetValues(form);
});

export { form as Resale };
