// TODO:
//   - On mobile when using search bar, display title and search icon. When user clicks icon then
//     hides title and allows for search string to be entered.

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import { Switch, Route } from "react-router-dom";
import Component from "./component";
// import compiler from 'mson/lib/compiler';
import { withRouter } from "react-router";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import attach from "./attach";
import globals from "mson/lib/globals";
import Snackbar from "./snackbar";
import ConfirmationDialog from "./confirmation-dialog";
import MUISwitch from "@material-ui/core/Switch";
// import UserMenu from './user-menu';
import Action from "mson/lib/actions/action";
import CollectionField from "mson/lib/fields/collection-field";
import Form from "mson/lib/form";
import access from "mson/lib/access";
import registrar from "mson/lib/compiler/registrar";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    width: "100%",
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
  },
  appBarResponsive: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  contentResponsive: {},
  alignRight: {
    marginLeft: "auto", // right align
  },
});

// TODO: break up into components for header, menu, body, etc...
class App extends React.PureComponent {
  state = {
    mobileOpen: false,
    menuItem: null,
    snackbarOpen: false,
    snackbarMessage: "",
    confirmationOpen: false,
    nextMenuItem: null,
    showArchivedToggle: false,

    // Note: we need both searchStringInput and globals.searchString as searchStringInput is the
    // controlled value for the text input and globals.searchString is the actual string with which
    // we are searching. These values not the same as we expect the user to submit the search before
    // it is performed so that we don't search on every keystroke. FUTURE: wait a little bit after
    // characters have been entered and then automatically search.
    searchStringInput: "",
    showSearch: false,

    showSearchOnMobile: false,

    // isLoggedIn: false
  };

  form = null;

  path = null;

  constructor(props) {
    super(props);
    this.setGlobalOnNavigate();
  }

  onNavigate = (callback) => {
    // We don't warn about discarding changes when fullScreen, e.g. a login page
    const menuItem = this.state.menuItem;
    if (
      menuItem &&
      menuItem.content.has("dirty") &&
      menuItem.content.get("dirty") &&
      !menuItem.fullScreen
    ) {
      // Show a confirmation dialog to see if the user wants to continue
      globals.displayConfirmation({
        title: "Discard changes?",
        callback,
      });
    } else {
      // Nothing is dirty so allow the navigation to continue
      callback(true);
    }
  };

  setGlobalOnNavigate() {
    globals.setOnNavigate(this.onNavigate);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  redirect(path) {
    const { history, match } = this.props;

    // Clear the redirectPath so that back-to-back redirects to the same route are considered
    // unique, e.g. if / routes to /somepage and then the user hits back.
    globals.set({ redirectPath: null });
    if (match.path.replace(/\/$/g, "") !== path.replace(/\/$/g, "")) {
      history.push(`${match.path.replace(/\/$/, "")}${path}`);
    }
  }

  navigateTo(path) {
    const { menuItem } = this.state;
    const { component } = this.props;
    const menu = component.get("menu");

    if (!menuItem || path !== menuItem.path) {
      // if (this.requireAccess(menu.get('roles'))) {
      // The route is changing
      const targetPath =
        this.props.match.path !== "/"
          ? path.replace(this.props.match.path, "")
          : path;
      const item = menu.getItemAndParsePath(targetPath);
      return this.switchContent(item.item, item.params);
      // }
    }
  }

  handleNavigate = async (menuItem, force) => {
    // Is the next item just an action?
    if (menuItem.content instanceof Action) {
      // Execute the actions
      await menuItem.content.run();
    } else {
      this.props.history.push(menuItem.path);
    }
  };

  handleConfirmationClose = async (yes) => {
    const { confirmation } = this.props;
    if (confirmation.callback && yes) {
      // Allow/prohibit the route change
      confirmation.callback(yes);
    }
    this.setState({ confirmationOpen: false });
  };

  canArchive() {
    let canArchive = false;
    let canSearch = false;
    if (this.component && this.component instanceof Form) {
      for (const field of this.component.getFields()) {
        if (field instanceof CollectionField) {
          canArchive =
            !field.get("forbidViewArchived") &&
            access.canArchive(field.get("form"));
          canSearch = !field.get("forbidSearch");
        }
      }
    }
    return {
      canArchive,
      canSearch,
    };
  }

  emitLoggedOut() {
    globals.set({ redirectAfterLogin: this.props.location.pathname });
    this.props.component.emitLoggedOut();
  }

  requireAccess(roles) {
    const canAccess =
      !roles || (registrar.client && registrar.client.user.hasRole(roles));
    if (!canAccess) {
      this.emitLoggedOut();
    }
    return canAccess;
  }

  switchContent = async (menuItem, parameters) => {
    // Prevent inifinite recursion when menuItem is null by making sure that the menuItem is
    // changing before changing anything, especially the state
    let parametersDiffer = false;
    if (globals.get("route")) {
      const previousParams = globals.get("route").parameters;
      if (
        Object.keys(parameters).length !== Object.keys(previousParams).length
      ) {
        parametersDiffer = true;
      } else {
        parametersDiffer = Object.keys(parameters).reduce(
          (result, paramKey) =>
            result || parameters[paramKey] !== previousParams[paramKey],
          parametersDiffer
        );
      }
    }
    if (menuItem !== this.state.menuItem || parametersDiffer) {
      if (this.component) {
        // Emit an unload event so that the component can unload any data, etc...
        this.component.emitUnload();
      }

      // Note: menuItem can be null if there is no content on the landing page
      const isAction = menuItem && menuItem.content instanceof Action;

      // Note: menuItem.content can be an action if the user goes directly to a route where the
      // content is an action
      if (menuItem && menuItem.content) {
        const { location, component } = this.props;
        const menu = component.get("menu");
        globals.set({
          route: menu.toRoute({
            parameters,
            queryString: location.search.substr(1),
            hash: location.hash.substr(1),
          }),
        });

        const parentItem = menu.getParent(menuItem.path);
        if (
          this.requireAccess(menuItem.roles) &&
          (!parentItem || this.requireAccess(parentItem.roles))
        ) {
          if (isAction) {
            // Execute the actions
            await menuItem.content.run();
          } else {
            // Instantiate form
            // this.component = compiler.newComponent(menuItem.content.component);
            this.component = menuItem.content;

            // Emit a load event so that the component can load any initial data, etc...
            this.component.emitLoad();

            const { canArchive, canSearch } = this.canArchive();

            globals.set({ searchString: null });

            // Set showArchived to false whenever we change the route
            this.setState({
              menuItem,
              showArchived: false,
              showArchivedToggle: canArchive,
              searchStringInput: "",
              showSearch: canSearch,
            });
          }
        }
      } else {
        this.component = null;
      }
    }
  };

  onLocation = (location) => {
    globals.set({ path: location.pathname });
  };

  componentDidUpdate(prevProps) {
    const snackbarMessage = globals.get("snackbarMessage");
    if (snackbarMessage) {
      this.displaySnackbar(snackbarMessage);
      globals.set({ snackbarMessage: null });
    }

    if (
      this.props.redirectPath &&
      this.props.redirectPath !== prevProps.redirectPath
    ) {
      this.redirect(this.props.redirectPath);
    }

    if (this.props.path !== prevProps.path) {
      this.navigateTo(this.props.path);
    }

    if (this.props.confirmation !== prevProps.confirmation) {
      // Show the popup if any of the confirmation info has changed
      this.setState({ confirmationOpen: true });
    }

    if (this.props.searchString !== prevProps.searchString) {
      // Pass search string down to current component
      const menuItem = this.state.menuItem;
      if (menuItem && menuItem.content.has("searchString")) {
        menuItem.content.set({
          searchString: this.props.searchString,
        });
      }
    }
  }

  componentDidMount() {
    // Allows us to listen to back and forward button clicks
    this.unlisten = this.props.history.listen(this.onLocation);

    Promise.resolve()
      .then(() => {
        if (registrar.client) {
          // Wait for the session to load before loading the initial component so that we can do things
          // like route based on a user's role
          registrar.client.user.awaitSession();
        }
      })
      .then(() => {
        // Load the correct component based on the initial path
        this.onLocation(this.props.location);
      });

    // TODO: is this too inefficient in that it cascades a lot of unecessary events? Instead, could:
    // 1. move more logic to app layer so that only cascade when need new window 2. use something
    // like a global scroll listener that the component can use when it is active
    window.addEventListener("scroll", (e) => {
      if (this.state.menuItem) {
        this.state.menuItem.content.emit("scroll", e);
      }
    });

    // Handle immediate redirects, e.g. if user is not logged in
    if (this.props.redirectPath) {
      this.redirect(this.props.redirectPath);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  displaySnackbar(message) {
    this.setState({ snackbarOpen: true, snackbarMessage: message });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  isResponsive() {
    return !this.props.menuAlwaysTemporary;
  }

  render() {
    const { classes, component, confirmation, path, match, className } =
      this.props;
    const {
      mobileOpen,
      menuItem,
      snackbarOpen,
      snackbarMessage,
      confirmationOpen,
    } = this.state;

    const responsive = this.isResponsive();

    // Use the path from the location prop as this.state.path may not be up to date
    // const path = this.props.location.pathname;

    const comp = this.component ? (
      <Component component={this.component} />
    ) : null;

    let fullScreenStyle = null;
    if (menuItem && menuItem.fullScreen) {
      fullScreenStyle = {
        marginLeft: 0,
        marginTop: 0,
      };
    }

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <main className={className}>
            <Switch>
              {/* Omitting path so that all paths are matched */}
              <Route />
            </Switch>

            {comp}

            <Snackbar
              open={snackbarOpen}
              message={snackbarMessage}
              onClose={this.handleSnackbarClose}
            />
            <ConfirmationDialog
              open={confirmationOpen}
              onClose={this.handleConfirmationClose}
              title={confirmation ? confirmation.title : null}
              text={confirmation ? confirmation.text : null}
              alert={confirmation ? confirmation.alert : null}
            />
          </main>
        </div>
      </div>
    );
  }
}

App = withStyles(styles, { withTheme: true })(App);
App = withWidth()(App);
App = withRouter(App);
App = attach(["menuAlwaysTemporary"])(App);
App = attach(
  ["path", "redirectPath", "snackbarMessage", "confirmation", "searchString"],
  globals
)(App);
export default App;
