/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const emailQRCode = /* GraphQL */ `
  mutation EmailQRCode(
    $QRCodeBase: String!
    $Name: String!
    $EmailAddress: String!
    $Language: String!
  ) {
    EmailQRCode(
      QRCodeBase: $QRCodeBase
      Name: $Name
      EmailAddress: $EmailAddress
      Language: $Language
    )
  }
`;
export const updateCustomerResponse = /* GraphQL */ `
  mutation UpdateCustomerResponse(
    $input: UpdateCustomerResponseInput!
    $condition: ModelCustomerResponseConditionInput
  ) {
    updateCustomerResponse(input: $input, condition: $condition) {
      id
      QRCodeBase
      PartyID
      ArrivalDate
      ResidentForm
      ConsentForm
      CurrencyVisitorCrewForm
      CustomerForm
      DeclarationForm
      HealthForm1
      HealthForm2
      HealthForm3
      HealthForm4
      HealthForm5
      HealthForm6
      HealthForm7
      HealthForm8
      PackingList
      ResaleForm
      VisitorSpecificForm
      IntendedResident
      TransitForm
      createdAt
      updatedAt
      agentActions {
        items {
          id
          QRCodeBase
          action
          agent
          userId
          secondary
          location
          createdAt
          updatedAt
        }
        nextToken
      }
      agentComments {
        items {
          id
          QRCodeBase
          PartyID
          agent
          comment
          agentToTraveller
          location
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCustomerResponse = /* GraphQL */ `
  mutation DeleteCustomerResponse(
    $input: DeleteCustomerResponseInput!
    $condition: ModelCustomerResponseConditionInput
  ) {
    deleteCustomerResponse(input: $input, condition: $condition) {
      id
      QRCodeBase
      PartyID
      ArrivalDate
      ResidentForm
      ConsentForm
      CurrencyVisitorCrewForm
      CustomerForm
      DeclarationForm
      HealthForm1
      HealthForm2
      HealthForm3
      HealthForm4
      HealthForm5
      HealthForm6
      HealthForm7
      HealthForm8
      PackingList
      ResaleForm
      VisitorSpecificForm
      IntendedResident
      TransitForm
      createdAt
      updatedAt
      agentActions {
        items {
          id
          QRCodeBase
          action
          agent
          userId
          secondary
          location
          createdAt
          updatedAt
        }
        nextToken
      }
      agentComments {
        items {
          id
          QRCodeBase
          PartyID
          agent
          comment
          agentToTraveller
          location
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createReportExtract = /* GraphQL */ `
  mutation CreateReportExtract(
    $input: CreateReportExtractInput!
    $condition: ModelReportExtractConditionInput
  ) {
    createReportExtract(input: $input, condition: $condition) {
      id
      exportedAt
      user
      userId
      content
      agency
      type
      exportFrom
      exportTo
      createdAt
      updatedAt
    }
  }
`;
export const updateReportExtract = /* GraphQL */ `
  mutation UpdateReportExtract(
    $input: UpdateReportExtractInput!
    $condition: ModelReportExtractConditionInput
  ) {
    updateReportExtract(input: $input, condition: $condition) {
      id
      exportedAt
      user
      userId
      content
      agency
      type
      exportFrom
      exportTo
      createdAt
      updatedAt
    }
  }
`;
export const deleteReportExtract = /* GraphQL */ `
  mutation DeleteReportExtract(
    $input: DeleteReportExtractInput!
    $condition: ModelReportExtractConditionInput
  ) {
    deleteReportExtract(input: $input, condition: $condition) {
      id
      exportedAt
      user
      userId
      content
      agency
      type
      exportFrom
      exportTo
      createdAt
      updatedAt
    }
  }
`;
export const createAgentResponse = /* GraphQL */ `
  mutation CreateAgentResponse(
    $input: CreateAgentResponseInput!
    $condition: ModelAgentResponseConditionInput
  ) {
    createAgentResponse(input: $input, condition: $condition) {
      id
      QRCodeBase
      action
      agent
      userId
      secondary
      location
      createdAt
      updatedAt
    }
  }
`;
export const updateAgentResponse = /* GraphQL */ `
  mutation UpdateAgentResponse(
    $input: UpdateAgentResponseInput!
    $condition: ModelAgentResponseConditionInput
  ) {
    updateAgentResponse(input: $input, condition: $condition) {
      id
      QRCodeBase
      action
      agent
      userId
      secondary
      location
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgentResponse = /* GraphQL */ `
  mutation DeleteAgentResponse(
    $input: DeleteAgentResponseInput!
    $condition: ModelAgentResponseConditionInput
  ) {
    deleteAgentResponse(input: $input, condition: $condition) {
      id
      QRCodeBase
      action
      agent
      userId
      secondary
      location
      createdAt
      updatedAt
    }
  }
`;
export const createAgentComment = /* GraphQL */ `
  mutation CreateAgentComment(
    $input: CreateAgentCommentInput!
    $condition: ModelAgentCommentConditionInput
  ) {
    createAgentComment(input: $input, condition: $condition) {
      id
      QRCodeBase
      PartyID
      agent
      comment
      agentToTraveller
      location
      createdAt
      updatedAt
    }
  }
`;
export const updateAgentComment = /* GraphQL */ `
  mutation UpdateAgentComment(
    $input: UpdateAgentCommentInput!
    $condition: ModelAgentCommentConditionInput
  ) {
    updateAgentComment(input: $input, condition: $condition) {
      id
      QRCodeBase
      PartyID
      agent
      comment
      agentToTraveller
      location
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgentComment = /* GraphQL */ `
  mutation DeleteAgentComment(
    $input: DeleteAgentCommentInput!
    $condition: ModelAgentCommentConditionInput
  ) {
    deleteAgentComment(input: $input, condition: $condition) {
      id
      QRCodeBase
      PartyID
      agent
      comment
      agentToTraveller
      location
      createdAt
      updatedAt
    }
  }
`;
export const createAirline = /* GraphQL */ `
  mutation CreateAirline(
    $input: CreateAirlineInput!
    $condition: ModelAirlineConditionInput
  ) {
    createAirline(input: $input, condition: $condition) {
      id
      name
      abbreviation
      active
      createdAt
      updatedAt
      flights {
        items {
          id
          number
          location
          active
          airlineID
          createdAt
          updatedAt
          airline {
            id
            name
            abbreviation
            active
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateAirline = /* GraphQL */ `
  mutation UpdateAirline(
    $input: UpdateAirlineInput!
    $condition: ModelAirlineConditionInput
  ) {
    updateAirline(input: $input, condition: $condition) {
      id
      name
      abbreviation
      active
      createdAt
      updatedAt
      flights {
        items {
          id
          number
          location
          active
          airlineID
          createdAt
          updatedAt
          airline {
            id
            name
            abbreviation
            active
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteAirline = /* GraphQL */ `
  mutation DeleteAirline(
    $input: DeleteAirlineInput!
    $condition: ModelAirlineConditionInput
  ) {
    deleteAirline(input: $input, condition: $condition) {
      id
      name
      abbreviation
      active
      createdAt
      updatedAt
      flights {
        items {
          id
          number
          location
          active
          airlineID
          createdAt
          updatedAt
          airline {
            id
            name
            abbreviation
            active
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createFlight = /* GraphQL */ `
  mutation CreateFlight(
    $input: CreateFlightInput!
    $condition: ModelFlightConditionInput
  ) {
    createFlight(input: $input, condition: $condition) {
      id
      number
      location
      active
      airlineID
      createdAt
      updatedAt
      airline {
        id
        name
        abbreviation
        active
        createdAt
        updatedAt
        flights {
          items {
            id
            number
            location
            active
            airlineID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateFlight = /* GraphQL */ `
  mutation UpdateFlight(
    $input: UpdateFlightInput!
    $condition: ModelFlightConditionInput
  ) {
    updateFlight(input: $input, condition: $condition) {
      id
      number
      location
      active
      airlineID
      createdAt
      updatedAt
      airline {
        id
        name
        abbreviation
        active
        createdAt
        updatedAt
        flights {
          items {
            id
            number
            location
            active
            airlineID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteFlight = /* GraphQL */ `
  mutation DeleteFlight(
    $input: DeleteFlightInput!
    $condition: ModelFlightConditionInput
  ) {
    deleteFlight(input: $input, condition: $condition) {
      id
      number
      location
      active
      airlineID
      createdAt
      updatedAt
      airline {
        id
        name
        abbreviation
        active
        createdAt
        updatedAt
        flights {
          items {
            id
            number
            location
            active
            airlineID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCode = /* GraphQL */ `
  mutation CreateCode(
    $input: CreateCodeInput!
    $condition: ModelCodeConditionInput
  ) {
    createCode(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
    }
  }
`;
export const updateCode = /* GraphQL */ `
  mutation UpdateCode(
    $input: UpdateCodeInput!
    $condition: ModelCodeConditionInput
  ) {
    updateCode(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
    }
  }
`;
export const deleteCode = /* GraphQL */ `
  mutation DeleteCode(
    $input: DeleteCodeInput!
    $condition: ModelCodeConditionInput
  ) {
    deleteCode(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
    }
  }
`;
export const createSecondary = /* GraphQL */ `
  mutation CreateSecondary(
    $input: CreateSecondaryInput!
    $condition: ModelSecondaryConditionInput
  ) {
    createSecondary(input: $input, condition: $condition) {
      id
      number
      text
      active
      codeID
      code {
        id
        code
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSecondary = /* GraphQL */ `
  mutation UpdateSecondary(
    $input: UpdateSecondaryInput!
    $condition: ModelSecondaryConditionInput
  ) {
    updateSecondary(input: $input, condition: $condition) {
      id
      number
      text
      active
      codeID
      code {
        id
        code
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecondary = /* GraphQL */ `
  mutation DeleteSecondary(
    $input: DeleteSecondaryInput!
    $condition: ModelSecondaryConditionInput
  ) {
    deleteSecondary(input: $input, condition: $condition) {
      id
      number
      text
      active
      codeID
      code {
        id
        code
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerResponse = /* GraphQL */ `
  mutation CreateCustomerResponse(
    $input: CreateCustomerResponseInput!
    $condition: ModelCustomerResponseConditionInput
  ) {
    createCustomerResponse(input: $input, condition: $condition) {
      id
      QRCodeBase
      PartyID
      ArrivalDate
      ResidentForm
      ConsentForm
      CurrencyVisitorCrewForm
      CustomerForm
      DeclarationForm
      HealthForm1
      HealthForm2
      HealthForm3
      HealthForm4
      HealthForm5
      HealthForm6
      HealthForm7
      HealthForm8
      PackingList
      ResaleForm
      VisitorSpecificForm
      IntendedResident
      TransitForm
      createdAt
      updatedAt
      agentActions {
        items {
          id
          QRCodeBase
          action
          agent
          userId
          secondary
          location
          createdAt
          updatedAt
        }
        nextToken
      }
      agentComments {
        items {
          id
          QRCodeBase
          PartyID
          agent
          comment
          agentToTraveller
          location
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
