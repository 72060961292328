import logo from "../../customs.png";
import { generateYears } from "../options";

export default {
  options: {
    russiaCities: [
      "Moscow",
      "Khabarovsk",
      "Y-Sakhalinsk",
      "Irkutsk",
      "Vladivostok",
      "Other",
    ],
    taiwanCities: [
      "Taipei",
      "Taichung",
      "Taoyuan",
      "Hsinchu",
      "Kaohsiung",
      "Other",
    ],
    chinaCities: [
      "Shanghai",
      "Beijing",
      "Guangzhou",
      "Hangzhou",
      "Dalian",
      "Nanjing",
      "Harbin",
      "Zhengzhou",
      "Shenyang",
      "Chengdu",
      "Other",
    ],
    koreaCities: [
      "Seoul",
      "Busan",
      "Incheon/Gyeonggi",
      "Daegu",
      "Daejeon",
      "Gwangju",
      "Ulsan",
      "Other",
    ],
    japanCities: [
      "Kanto",
      "Kinki",
      "Chubu",
      "Hokkaido",
      "Tohoku",
      "Koshinetsu",
      "Hokuriku",
      "Chugoku",
      "Shikoku",
      "Kyushu",
      "Okinawa",
      "Other",
    ],
    guamStatus: [
      "Transiting",
      "Flight Crew - Terminating",
      "Flight Crew - In Transit",
      "Returning Resident",
    ],
    status: [
      "Visitor",
      "Transiting",
      "Flight Crew - Terminating",
      "Flight Crew - In Transit",
      "Intended Resident - moving to Guam for at least one year",
      "Returning Resident",
    ],
    country: [
      "Guam",
      "USA",
      "Japan",
      "Korea",
      "China",
      "Taiwan",
      "Australia",
      "CNMI",
      "Europe",
      "FSM",
      "Hong Kong",
      "India",
      "Malaysia",
      "Palau",
      "Philippines",
      "RMI",
      "Singapore",
      "Russia",
      "Other",
    ],
    year: generateYears(),
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    day: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    reason: [
      "Pleasure/Vacation",
      "To get married",
      "Honeymoon",
      "Meeting/Conference",
      "Incentive Trip",
      "Other Business",
      "Visiting friends or relatives",
      "Government or Military",
      "School Trip",
      "Sports Event",
      "Medical",
      "Other",
    ],
    length: [
      "Just a few hours layover",
      "For a day trip",
      "1 night",
      "2 nights",
      "3 nights",
      "4 nights",
      "5 nights",
      "6 nights",
      "7 – 10 nights",
      "11 – 15 nights",
      "16 – 30 nights",
      "1 – 3 months",
      "3 months – 1 year",
    ],
    trip: ["1st", "2nd", "3rd", "4th", "5th", "6th or more"],
    arrangement: [
      "Travel Agent",
      "Travel Website",
      "Direct Booking (Airline/Accommodation)",
    ],
    stay: [
      "Hotel",
      "Vacation Rental",
      "Cruise Ship",
      "Friends or Relatives",
      "Bed and Breakfast",
      "Military Facility",
      "Timeshare",
      "Other",
    ],
  },
  DD: "DD",
  MM: "MM",
  YYYY: "YYYY",
  dob: "Date of Birth",
  heading: `![logo](${logo})\n\n# Guam Customs and Quarantine Agency\n\n## Electronic Declaration Form\n\n`,
  healthFormDescription:
    "## Health Declaration Form\n\n## Each arriving traveler must submit a completed Health Declaration (one Declaration form per passenger is required). PLEASE COMPLETE THE FORM IN ENGLISH.",
  // healthFormDescription: heading+"## Health Declaration Form\n\n## Each arriving traveler must submit a completed Health Declaration (one Declaration form per passenger is required). PLEASE COMPLETE THE FORM IN ENGLISH.",
  intendedResidentDescription: "# Intended Resident",
  nameFieldLabel: "Your full name",
  firstNameLabel: "First",
  middleNameLabel: "Middle",
  lastNameLabel: "Last",
  packingListTitleLabel:
    "## Declaration of Goods\n\nWARNING: The importation of controlled substances into Guam is a felony of the first degree (Title 9 GCA, Section 67). Violation of this section carries a mandatory prison sentence of up to ten (10) years. False statements made to a Customs Officer are punishable by law. All of your baggage, handbags and hand-carried articles may be opened and examined. If you have any questions about what must be reported or declared, ask a Customs Officer. To prevent the entry of dangerous agricultural pests or prohibited wildlife, the following are regulated and/or restricted: fruits, vegetables, plants, plant products, soil, meat, meat products, birds, snails and other live animals or animal products. Failure to declare such items may be subject to seizure and/or additional penalties. The Internal Revenue Laws of Guam require that you declare ALL goods, articles, or merchandise acquired abroad, whether worn or used, taxable or not, and whether obtained by purchase, as a gift or otherwise. Declare all goods or merchandise",
  personalGoodsLabel:
    "### Personal Goods:\n(Guam CQA to insert description/information on allowable amounts and limitations)",
  excisableGoodsLabel:
    "### Excisable Goods:\n(Guam CQA to insert description/information on allowable amounts and limitations)",
  bringingLabel: "I am (We are) bringing to Guam:",
  substancesLabel: "Controlled substances (prescription or otherwise)",
  firearmsLabel: "Firearms (personal owned)",
  explosivesLabel:
    "Explosives (commercial or consumer grade to include fireworks)",
  animalsLabel:
    "Animals or parts of animals or articles manufactured from wildlife",
  animalProductsLabel: "Animal products including meats, milk or eggs",
  plantsLabel:
    "Plants or parts of plants, including fresh fruits, vegetables, seeds flowers, or articles made of plant materials",
  soilLabel: "Soil materials or samples, or biological specimens",
  serviceAnimalLabel: "Live Service Animal",
  cigarettesLabel:
    "More than five (5) cartons of cigarettes (1,000 cigarettes) or twelve (12) packages of other tobacco products",
  alcoholLabel:
    "More than one (1) U.S. gallon/3.7 liters of alcoholic beverage per adult",
  welcomeText:
    "### Håfa Adai! Thank you for choosing Guam as your next travel destination.\nFor your safety, we are implementing an electronic Guam Customs Declaration Form that is required for all persons arriving into Guam. Please read the following information and complete the form to ensure compliance with United States and Guam laws prior to your arrival. Making false declarations in this form are punishable by law.\n\n_For your convenience, the form may be completed up to 72 hours prior to your arrival date._",
  welcomeButtonText: "I AM READY TO FILL OUT THE FORM",
  visitorSpecificDescriptionLabel: "# Visitor Information",
  onlineBookingLabel: "Did you book your travel arrangements online?",
  genderLabel: "Gender",
  emailLabel: "Email",
  TelephoneNumber: "Telephone Number",
  NationalityLabel: "Nationality",
  WorkplaceLabel: "Workplace",
  OccupationLabel: "Occupation",
  SymptomsLabel:
    "Please check if you have any of the following at present or during the past 14 days:",
  FeverLabel: "Fever (°F/C)",
  Headache: "Headache",
  Cough: "Cough",
  SoreThroat: "Sore Throat",
  BodyWeakness: "Body Weakness",
  JointMusclePain: "Joint / Muscle Pain",
  BruisingBleeding: "Bruising / Bleeding",
  Rashes: "Rashes",
  RedEyes: "RedEyes",
  Nausea: "Nausea",
  Vomiting: "Vomiting",
  Diarrhea: "Diarrhea",
  DifficultybreathingShortnessofbreath:
    "Difficulty breathing / Shortness of breath",
  Other: "Other",
  healthWorkerLabel:
    "DID YOU VISIT ANY HEALTH WORKER, HOSPITAL, CLINIC OR NURSING HOME?",
  poultryFarmLabel: "DID YOU VISIT ANY POULTRY FARM OR ANIMAL MARKET?",
  contactRespiratoryLabel:
    "HAVE YOU OR ANY OF YOUR FAMILY MEMBERS BEEN IN CLOSE CONTACT WITH A PERSON WHO HAS A FEVER, COUGH AND/OR RESPIRATORY PROBLEMS?",
  sickFamilyGroupLabel: "ANY OTHERS SICK WITHIN YOUR FAMILY/GROUP?",
  recentMedicationLabel: "LIST ANY MEDICATION TAKEN IN THE LAST 4-6 HRS:",
  dphssText:
    "The Department of Public Health and Social Services’ (DPHSS) mission is to protect Guam against all health-related threats that pose the greatest risk to our island community. During a Public Health Emergency, health screeners are authorized to ask you questions involving prior travel history and health information.\n\nFALSE STATEMENTS ARE PUNISHABLE BY LAW.\n\nI DECLARE UNDER PENALTY OF PERJURY UNDER THE LAWS OF GUAM THAT I HAVE READ AND UNDERSTOOD THE REQUIREMENTS OF THIS FORM AND THAT ALL WRITTEN STATEMENTS THAT I HAVE MADE ARE TRUE AND CORRECT",
  promoLabel: "If you’d like to receive promotional info from GVB",
  descriptionLabel:
    "# Personal Information\nEach arriving passenger or responsible family member must provide the following information, in English (only ONE declaration per family is required). The term “family” is defined as members of a family residing in the same household who are related by blood, marriage, domestic relationship, or adoption.",
  todaysDateLabel: "Arrival Date – to Guam",
  crewLabel: "Crew",
  airlineLabel: "Airline/Vessel",
  flightLabel: "Flight/Voyage No.",
  flightCityLabel: "Country/City",
  countryLabel: "Country",
  originatingLabel: "Originating From",
  originatingCountryLabel:
    "Originating From (country from which you started your journey)",
  originatingCityLabel:
    "Originating From (city from which you started your journey)",
  dobLabel: "Birth Date",
  familyMembersLabel:
    "Total family members (including yourself) traveling with you",
  passportCountryLabel: "Country of Issuance",
  passportNoLabel: "Passport No./Travel ID",
  // passportNoLabel: "Passport No./Drivers License/Alien Reg No./Sea Fearers ID",
  placeOfResidenceLabel:
    "Primary Place of Residence - country/city (city only where applicable)",
  arrivalInformationLabel: "## Arrival Information",
  countryOfOriginLabel: "Primary Place Of Residence",
  // countryOfOriginLabel: "Country of Origin (where do you live)",
  addressOnGuamLabel: "Address/Hotel Name (while on Guam)",
  statusLabel: "Status",
  transitFormDescription: "# Transiting",
  residentFormDescription: "# Returning Resident",
  returningReasonLabel: "Primary reason for trip just completed",
  transitingDestination: "Final Destination",
  resaleTitle: "# Commercial Merchandise",
  currencyVisitorCrewTitle: "# Currency or Monetary Instrument",
  currencyVisitorCrewDescription:
    "The transportation of currency or monetary instruments, regardless of the amount, is legal. However, if you bring in or take out of the United States more than $10,000 (U.S. or foreign equivalent, or a combination of both), you are required by law to file a report on FinCEN 105 (formerly Customs Form 4790) with a Customs Officer. Monetary instruments include coin, currency, travelers checks and bearer instruments such as personal or cashiers checks and stocks and bonds. If you have someone else carry the currency or monetary instrument for you, you must also file a report on FinCEN 105. Failure to file the required report or failure to report the total amount that you are carrying may lead to the seizure of all the currency or monetary instruments, and may subject you to civil penalties and/or criminal prosecution.",
  // currencyVisitorCrewDescription: "The transportation of currency or monetary instruments, regardless of the amount, is legal. However, if you bring in or take out of the United States more than $10,000 (U.S. or foreign equivalent, or a combination of both), you are required by law to file a report on FinCEN 105 (formerly Customs Form 4790) with a Customs Officer. Monetary instruments include coin, currency, travelers checks and bearer instruments such as personal or cashiers checks and stocks and bonds. If you have someone else carry the currency or monetary instrument for you, you must also file a report on FinCEN 105. Failure to file the required report or failure to report the total amount that you are carrying may lead to the seizure of all the currency or monetary instruments, and may subject you to civil penalties and/or criminal prosecution.",
  over10kLabel:
    "I have (We have) currency of monetary instruments over $10,000 USD or foreign equivalent",
  resaleLabel: "I have (We have) commercial merchandise (goods for resale)",
  remainLabel:
    "VISITORS - The total value of all articles that will remain in Guam (commercial merchandise only; goods for resale) is: (US$)",
  declarationDescription:
    "# Declaration of Goods\nThe Guam Customs and Quarantine Agency is responsible for protecting Guam against the illegal importation of prohibited items. Customs Officers have the authority to question and examine you and your personal property. If selected for examination, you will be treated in a courteous, professional, and dignified manner. The Customs Officer Supervisor or Officer-In-Charge on duty is available to answer your questions.\n\nWARNING: The importation of controlled substances into Guam is a felony of the first degree (Title 9 GCA, Section 67). Violation of this section carries a mandatory prison sentence of up to ten (10) years. False statements made to a Customs Officer are punishable by law. All of your baggage, handbags and hand-carried articles may be opened and examined. If you have any questions about what must be reported or declared, ask a Customs Officer. To prevent the entry of dangerous agricultural pests or prohibited wildlife, the following are restricted: fruits, vegetables, plants, plant products, soil, meat, meat products, birds, snails and other live animals or animal products. Failure to declare such items may be subject to seizure. The Internal Revenue Laws of Guam require that you declare ALL goods, articles, or merchandise acquired abroad, whether worn or used, taxable or not, and whether obtained by purchase, as a gift or otherwise. List all goods or merchandise.",
  declarationPreviousLabel:
    "In the previous page, you declared 'Yes’ to one of the below:",
  declarationDescriptionLabel:
    "Please describe the goods, or any additional goods you would like to declare.",
  consentDescriptionLabel:
    "## Certification Statement\n\nThe Guam Customs and Quarantine Agency is responsible for protecting Guam against the importation of illegal and prohibited items. Customs Officers have the authority to question and examine you and your personal property. If selected for examination, you will be treated in a courteous, professional, and dignified manner. The Customs Officer Supervisor or Officer-In-Charge on duty is available to answer your questions.",
  consentCertifyFieldLabel:
    "I CERTIFY THAT I HAVE READ AND UNDERSTAND THE REQUIREMENTS ON THIS FORM AND CONSENT THAT ALL ORAL AND WRITTEN STATEMENTS WHICH I HAVE MADE ARE A TRUTHFUL DECLARATION.",
  Yes: "Yes",
  No: "No",
  lengthOfStayLabel: "Length of stay",
  tripDescription: "This trip to Guam is my:",
  arrangementsLabel: "Travel Arrangements were made through a:",
  placeOfStayLabel: "Where will you stay while on Guam (mark all that apply)",
  medicationLabel: "LIST ANY MEDICATION TAKEN IN THE LAST 4-6 HRS:",
  nextLabel: "Next",
  prevLabel: "Prev",
  resetLabel: "Reset",
  AdditionalLabelText: "## Additional Family Members",
  FullNameText: "Full Name",
  person2: "Person 2",
  person1: "Person 1",
  tripReasonLabelText: "Primary reason for this trip",
  EmailCollectionText:
    "If you are interested in receiving information from the Guam Visitors Bureau on future events and promotions, please provide your mobile phone number and/or e-mail address below.",
  PhoneFieldLabel: "Mobile Phone Number",
  EveryFieldLabel: "You must answer every field to continue.",
  DeclarationNALabel:
    "You have indicated that you are NOT bringing any of the listed goods into Guam. Please list below any additional goods that you would like to declare.",
  GoodsDescriptionLabel: "Description of Goods:",
  YesOrNoLabel: "Please choose Yes or No",
  EnterValueLabel: "Please enter a value",
  ResidentsTotalValueLabel:
    "RESIDENTS - The total value of all goods, including commercial merchandise acquired abroad, including gifts brought into Guam is: ($)",
  SeatNumberLabel: "Seat Number",
  CountriesVisitedLabel:
    "Please check/list all countries visited and transited in the last 14 days:",
  ChinaVisitedLabel: "China",
  IranVisitedLabel: "Iran",
  ItalyVisitedLabel: "Italy",
  SouthKoreaVisitedLabel: "South Korea",
  JapanVisitedLabel: "Japan",
  HongKongVisitedLabel: "Hong Kong",
  MacaoSARVisitedLabel: "Macao SAR",
  VietnamVisitedLabel: "Vietnam",
  ThailandVisitedLabel: "Thailand",
  PhilippinesVisitedLabel: "Philippines",
  MalaysiaVisitedLabel: "Malaysia",
  TaiwanVisitedLabel: "Taiwan",
  CambodiaVisitedLabel: "Cambodia",
  SingaporeVisitedLabel: "Singapore",
  NewZealandVisitedLabel: "New Zealand",
  AustraliaVisitedLabel: "Australia",
  CanadaVisitedLabel: "Canada",
  AmericanSamoaVisitedLabel: "American Samoa",
  MarshallIslandsVisitedLabel: "Marshall Islands",
  FSMVisitedLabel: "FSM",
  CNMIVisitedLabel: "CNMI",
  PalauVisitedLabel: "Palau",
  USVisitedLabel: "US (specify City/State)",
  OtherVisitedLabel: "Other (specify)",
  FeverAdviceText:
    "(please only enter a fever if applicable. If you have not had a fever in the past 14 days leave this field blank)",
  Person2: "### Person 2",
  Person3: "### Person 3",
  Person4: "### Person 4",
  Person5: "### Person 5",
  Person6: "### Person 6",
  Person7: "### Person 7",
  Person8: "### Person 8",
  City: "City",
  required: "Required",
  acceptError: "🚫 You must accept to continue",
  Male: "Male",
  Female: "Female",
  QRCode: "QR Code",
  presentCode:
    "Thank you for completing the Guam Customs Electronic Declaration Form. Please print/save your unique QR Code to present to a Guam CQA Officer",
  finish: "FINISH",
  thankYou: "Si Yu’us Ma’åse’ (Thank you)",
  seeYouSoon: "We look forward to seeing you soon!",
  languages: {
    selectLanguage: "Select Language",
    languagePrompt:
      "We've provided the Guam EDF in multiple languages, please select the one most convenient for you",
    en: "English",
    jp: "Japanese (日本語)",
    kr: "Korean (한국어)",
    cn: "Traditional Chinese (繁體中文)",
  },
  countries: {
    AF: "Afghanistan",
    AX: "Åland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Andorra",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia (Plurinational State of)",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo (Democratic Republic of the)",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czechia",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran (Islamic Republic of)",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (Democratic People",
    KR: "Korea (Republic of)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia (the former Yugoslav Republic of)",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia (Federated States of)",
    MD: "Moldova (Republic of)",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine, State of",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom of Great Britain and Northern Ireland ",
    US: "United States of America",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela (Bolivarian Republic of)",
    VN: "Viet Nam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
  wantEmail:
    "If you would like a copy of this QR code sent to your email address, please enter your email address below.",
  keepOpen: "Otherwise, please keep this page open on your device.",
  showCode:
    "Thank you for completing the Guam Customs Electronic Declaration Form. Please show the QR code displayed below to a Guam CQA officer.",
  QREmailSubject: "Your QR Code for entry to Guam",
  hasErrors: "Please ensure you've completed the form",
  NonLatinCharacters: "The following characters are not english: ",
  errorOccured: "An error has occured, please try again.",
  emailSent: "Email sent succesfully.",
};
