import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { LangContext } from "../lib/LangContext";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import classnames from "classnames";
import CustomsLogo from "../customs.png";
import { GB, US, JP, TW as CN, KR } from "./flags";

const styles = (theme) => ({
  form: {
    margin: "auto",
  },
  name: {
    textAlign: "center",
    margin: "auto",
    marginBottom: "2em",
    marginTop: "2em",
  },
  code: {
    textAlign: "center",
    margin: "auto",
    width: "256px",
    height: "256px",
  },
  button: {
    marginTop: "33px",
  },
  languages: {
    display: "flex",
    flexWrap: "wrap",
  },
  languageButton: {
    flex: "1 100%",
    margin: "1em",
    "& .MuiButton-label": {
      justifyContent: "left",
      "& .MuiButton-startIcon": {
        height: "48px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      flex: "1 45%",
    },
  },
  separator: {},
});

const getLanguages = (classes) => [
  {
    code: "en",
    flags: (
      <span>
        <img src={GB} />
        <span className={classes.separator}> </span>
        <img src={US} />
      </span>
    ),
  },
  {
    code: "jp",
    flags: (
      <span>
        <img src={JP} />
      </span>
    ),
  },
  {
    code: "kr",
    flags: (
      <span>
        <img src={KR} />
      </span>
    ),
  },
  {
    code: "cn",
    flags: (
      <span>
        <img src={CN} />
      </span>
    ),
  },
];

export const Language = withStyles(styles, { withTheme: true })(
  ({ classes, pathRoot = "/", redirectTo, className, seedLanguage }) => {
    const { push, goBack } = useHistory();
    const { translate, setLang } = useContext(LangContext);
    const languages = getLanguages(classes);

    useEffect(() => {
      if (seedLanguage) {
        setLang(seedLanguage);
      }
    }, []);

    const onLanguageSelect = (code) => {
      setLang(code);
      if (redirectTo) {
        push(redirectTo);
      } else {
        goBack();
      }
    };

    return (
      <main className={className}>
        <div className={classnames([classes.form, "form"])}>
          <h4>{translate("{{{languages.selectLanguage}}}")}</h4>
          <p>{translate("{{{languages.languagePrompt}}}")}</p>
          <p></p>
          <hr noshade="true" />
          <div className={classes.languages}>
            {languages.map((lang) => (
              <Button
                variant="contained"
                color="primary"
                className={classes.languageButton}
                key={lang.code}
                startIcon={lang.flags}
                onClick={() => onLanguageSelect(lang.code)}
              >
                {translate(`{{{languages.${lang.code}}}}`)}
              </Button>
            ))}
          </div>
        </div>
      </main>
    );
  }
);
