import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";

import { loadValues, saveValues, resetValues, resetForm } from "./session";
import { LengthOfStay } from "./lengthOfStay";
import { TripToGuam } from "./tripToGuam";
import { TravelArrangements } from "./travelArrangements";
import { PlaceOfStay } from "./placeOfStay";
import { TripReason } from "./tripReason";
import navButtons from "./navButtons";
import { NextDestination, PreviousDestination } from "./destinations";

const EmailField = compiler.newComponent({
  component: "EmailField",
  name: "EmailField",
  label: "{{{emailLabel}}}",
  block: true,
});
const PhoneField = compiler.newComponent({
  component: "LatinOnlyTextField",
  name: "PhoneField",
  label: "{{{PhoneFieldLabel}}}",
});
const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  block: false,
});

const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  // block: true
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});

const PromoEmail = compiler.newComponent({
  name: "PromoEmailField",
  component: "BooleanField",
  label: "{{{promoLabel}}}",
  value: false,
});
const OnlineBooking = compiler.newComponent({
  component: "SelectField",
  name: "onlineBooking",
  label: "{{{onlineBookingLabel}}}",
  fullWidth: true,
  options: [
    { label: "{{{Yes}}}", value: "Yes" },
    { label: "{{{No}}}", value: "No" },
  ],
  autocomplete: false,
});
const form = compiler.newComponent({
  component: "Form",
  name: "app.VisitorSpecificForm",
  fields: [
    {
      name: "DescriptionField",
      text: "{{{heading}}}{{{visitorSpecificDescriptionLabel}}}",
      component: "Text",
    },
    LengthOfStay,
    TripToGuam,
    OnlineBooking,
    TravelArrangements,
    ...PlaceOfStay,
    TripReason,
    {
      name: "EmailCollectionLabel",
      component: "Text",
      text: "{{{EmailCollectionText}}}",
      // text: "If you are interested in receiving information from the Guam Visitors Bureau on future events and promotions, please provide your mobile phone number and/or e-mail address below."
    },
    EmailField,
    PhoneField,
    // ...Gender,
    previousButton,
    {
      name: "SubmitButton",
      label: "{{{nextLabel}}}", //TODO
      type: "submit",
      component: "ButtonField",
      block: false,
    },
    resetButton,
    // EmailField,
    // PromoEmail,
    // ...HomeButton
  ],
});

form["submit"] = () => {
  saveValues(form);
  form.setDirty(false);
  globals.redirect(NextDestination(form));
};

previousButton.on("click", () => {
  form.setDirty(false);
  globals.redirect(PreviousDestination(form));
});

form.on("load", (f) => {
  loadValues(form);
});
form.on("reset", (f) => {
  resetValues(form);
});

export { form as visitorSpecific };
