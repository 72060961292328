import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsconfig from "../aws-exports";
/*
The custom mutation does not have agentActions.
The change was made so that public API access can be removed from AgentResponse GraphQL type
*/
import { createCustomerResponse } from "../graphql/mutations_custom";

import { getAllValues } from "./session";

Amplify.configure(awsconfig);

const Save = async () => {
  await API.graphql({
    query: createCustomerResponse,
    authMode: "API_KEY",
    variables: { input: getAllValues() },
  });
};

export default Save;
