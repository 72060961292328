export const createCustomerResponse = /* GraphQL */ `
  mutation CreateCustomerResponse(
    $input: CreateCustomerResponseInput!
    $condition: ModelCustomerResponseConditionInput
  ) {
    createCustomerResponse(input: $input, condition: $condition) {
      id
      QRCodeBase
      PartyID
      ArrivalDate
      ResidentForm
      ConsentForm
      CurrencyVisitorCrewForm
      CustomerForm
      DeclarationForm
      HealthForm1
      HealthForm2
      HealthForm3
      HealthForm4
      HealthForm5
      HealthForm6
      HealthForm7
      HealthForm8
      PackingList
      ResaleForm
      VisitorSpecificForm
      IntendedResident
      TransitForm
      createdAt
      updatedAt
    }
  }
`;
