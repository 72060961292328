import React, { useContext } from "react";
import { LangContext } from "./LangContext";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import Flag from "react-flags";

const flagComponent = (opts) => {
  return (
    <Flag
      basePath="/img/flags"
      name={opts.name}
      format="png"
      pngSize={opts.size | "16"}
      shiny={false}
    />
  );
};
const flags = [
  { description: "English", name: "en", flag: flagComponent({ name: "GB" }) },
  { description: "Korean", name: "kr", flag: flagComponent({ name: "KR" }) },
  { description: "Japanese", name: "jp", flag: flagComponent({ name: "JP" }) },
  { description: "Chinese", name: "cn", flag: flagComponent({ name: "cn" }) },
];

let style = {
  // position: "absolute",
  // float: "right",
  // top: "25px",
  // right: "30px",
  height: "auto",
  zIndex: "1000000",
  backgroundColor: "white",
};
const ButtonStyle = {
  width: "16px",
  height: "16px",
};
const SetLang = (props) => {
  const { lang, setLang } = useContext(LangContext);
  const { mini, change, className } = props;
  const extraStyle = mini
    ? {
        position: "absolute",
        top: "0",
        right: "0",
      }
    : {};
  return (
    <div style={{ ...style, ...extraStyle }} className={className}>
      <FormControl>
        <Select
          value={lang}
          onChange={(e) => {
            setLang(e.target.value);
            if (change) change(e.target.value);
          }}
        >
          {flags.map((flag) => {
            const desc = mini ? "" : flag.description;
            return (
              <MenuItem value={flag.name} key={flag.name}>
                {flag.flag} {desc}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
export default SetLang;
