import Field from "mson/lib/fields/field";
import compiler from "mson/lib/compiler";

export default class LanguageField extends Field {
  _className = "LanguageField";

  _create(props) {
    super._create(props);

    this.set({
      schema: {
        component: "Form",
        fields: [
          {
            // Hide from docs as required doesn't make sense for a LanguageField
            name: "required",
          },
        ],
      },
    });
  }
}

compiler.registerComponent("LanguageField", LanguageField);
