const badValues = [
  "id",
  "userId",
  "createdAt",
  "updatedAt",
  "archivedAt",
  "order",
  "PreviousButton",
  "DescriptionField",
  "SubmitButton",
  "FormNumber",
  "FormCounter",
  "ResetButton",
];

let forms = [
  "app.ResidentForm",
  "app.ConsentForm",
  "app.CurrencyVisitorCrewForm",
  "app.DeclarationForm",
  "app.CustomerForm",
  "app.IntendedResident",
  "app.PackingList",
  "app.TransitForm",
  "app.VisitorSpecificForm",
];
const resetForm = (formObject) => {
  for (let propertyName in formObject.getValues()) {
    if (!badValues.includes(propertyName)) {
      formObject.getField(propertyName).setValue(null);
    }
  }
};

function allStorage() {
  let values = {},
    keys = Object.keys(localStorage).filter((key) => key.startsWith("app.")),
    i = keys.length;

  while (i--) {
    let fieldName = keys[i].replace("app.", "");
    values[fieldName] = localStorage.getItem(keys[i]);
  }

  return values;
}
const getAllValues = () => {
  // let returnValue = {};
  // forms.forEach((form) => {
  //   returnValue = {...returnValue,...getValues({_name: form})}
  // })
  const QRCodeBase = getValue("app.ConsentForm", "QRCodeBase");
  const partyID = getValue("app.ConsentForm", "PartyID");
  const ArrivalDate = getValue("app.CustomerForm", "ArrivalDate");
  return {
    ...allStorage(),
    QRCodeBase: QRCodeBase,
    ArrivalDate,
    PartyID: partyID,
  };
};
const saveAllValues = () => {
  const AllValues = getAllValues();
  localStorage.setItem("AllValues", JSON.stringify(allStorage()));
};
const startOver = () => {
  // forms.forEach((form) => {
  //   resetValues({_name: form});
  // })
  // location.href="/" // reloading to really clear things out
  localStorage.clear();
};
const getValue = (formName, value) => {
  if (!localStorage.getItem(formName)) return;
  return JSON.parse(localStorage.getItem(formName))[value];
};
const getValues = (formObject) => {
  if (!localStorage.getItem(formObject._name)) return;
  return JSON.parse(localStorage.getItem(formObject._name));
};
const loadValues = (formObject) => {
  window.scrollTo(0, 0);
  if (!localStorage.getItem(formObject._name)) return;
  const valueObject = JSON.parse(localStorage.getItem(formObject._name));
  for (let propertyName in valueObject) {
    if (!badValues.includes(propertyName)) {
      try {
        const fieldObject = formObject.getField(propertyName);
        fieldObject.setValue(valueObject[propertyName]);
        try {
          fieldObject._events.change();
        } catch (e) {}
      } catch (e) {
        console.log("bad form element here: %o", e);
      }
    }
  }
};
const saveValues = (formObject) => {
  localStorage.setItem(
    formObject._name,
    JSON.stringify(formObject.getValues())
  );
};
const resetValues = (formObject) => {
  localStorage.removeItem(formObject._name);
  Object.keys(formObject._fields._items).forEach((field) => {
    const fieldObject = formObject.getField(field);
    if (fieldObject._events.change) fieldObject._events.change();
  });
};

const getQRBase = () => {
  return getValue("app.ConsentForm", "QRCodeBase");
};
const getName = () => {
  const formValues = getValues({ _name: "app.CustomerForm" });
  const middle = formValues.MiddleNameField
    ? ` ${formValues.MiddleNameField} `
    : " ";
  return `${formValues.FirstNameField}${middle}${formValues.LastNameField}`;
};
const getStatus = () => {
  return getValue("app.CustomerForm", "StatusField");
};
const getFamilyValue = () => {
  return getValue("app.CustomerForm", "FamilyMembersField");
};
const getPackingList = () => {
  const packingValues = getValues({ _name: "app.PackingList" });
  if (!packingValues) return;
  return Object.keys(packingValues).filter(function (row) {
    return packingValues[row];
  });
};

export {
  loadValues,
  saveValues,
  resetValues,
  resetForm,
  startOver,
  getValues,
  getAllValues,
  getValue,
  forms,
  getQRBase,
  getFamilyValue,
  getPackingList,
  getName,
  getStatus,
  saveAllValues,
};
