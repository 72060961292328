import compiler from "mson/lib/compiler";

import * as cms from "./ro_cms";

const flightNumbers = compiler.newComponent({
  name: "Flight",
  label: "{{{flightLabel}}}",
  options: [],
  component: "SelectField",
  required: true,
  hidden: true,
  block: true,
  autocomplete: false,
  blankString: "{{{flightLabel}}}",
});
const airlines = compiler.newComponent({
  name: "Airline",
  label: "{{{airlineLabel}}}",
  options: [],
  component: "SelectField",
  autocomplete: false,
  required: true,
  block: true,
  blankString: "{{{airlineLabel}}}",
});

airlines.on("load", () => {
  cms.getAirlines().then((data) => {
    const items = data.data.listAirlines.items;
    if (items) {
      airlines.set({
        options: items.map((item) => {
          return {
            label: `${item.name}`,
            value: item.id,
          };
        }),
      });
    }
  });
  calculateAirlineVisbility();
});

const calculateAirlineVisbility = () => {
  flightNumbers.set({ value: null });
  const airlineValue = airlines.get("value");
  if (airlineValue) {
    cms.getFlightsByAirline(airlineValue).then((data) => {
      const items = data.data.listFlights.items;
      if (items && items.length > 0) {
        airlines.set({ block: false });
        flightNumbers.set({
          options: items.map((item) => {
            return {
              label: `${item.number}/${item.location}`,
              value: `${item.number}/${item.location}`,
            };
          }),
        });
        flightNumbers.set({ hidden: false });
        flightNumbers.set({ required: true });
        if (flightNumbers._options.length === 1) {
          flightNumbers.set({ value: flightNumbers._options[0].value });
        }
      } else {
        airlines.set({ block: true });
        flightNumbers.set({ value: null });
        flightNumbers.set({ hidden: true });
        flightNumbers.set({ required: false });
      }
    });
  } else {
    airlines.set({ block: true });
    flightNumbers.set({ value: null });
    flightNumbers.set({ hidden: true });
    flightNumbers.set({ required: false });
  }
};

airlines.on("$change", (e) => {
  if (e !== "value") return;
  calculateAirlineVisbility();
});

const AirlineFlights = () => {
  return {
    Fields: [
      {
        name: "ArrivalLabelField",
        text: "{{{airlineLabel}}}",
        component: "Text",
      },
      airlines,
      flightNumbers,
    ],
  };
};

export default AirlineFlights;
