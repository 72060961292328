import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

import { loadValues, saveValues, resetValues, resetForm } from "./session";
import buildDeclaration from "./buildDeclaration";

const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  block: false,
});

const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  // block: true
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});
let fieldCount = 0;
const addField = () => {
  fieldCount++;
  return [
    {
      component: "LatinOnlyTextField",
      label: "{{{GoodsDescriptionLabel}}}",
      // label: "Description of Goods:",
      name: "GoodsDescription",
      fullWidth: true,
      required: true,
    },
  ];
};
const AddField = compiler.newComponent({
  component: "ButtonField",
  name: "AddAFieldButton",
  label: "+",
  type: "button",
});

AddField.on("click", () => {
  addField();
});

const declaredLabel = {
  component: "Text",
  name: "DeclaredField",
  text: "",
};
const form = compiler.newComponent({
  component: "Form",
  name: "app.DeclarationForm",
  fields: [
    {
      component: "Text",
      name: "DescriptionField",
      text: "{{{heading}}}",
    },
    {
      component: "Text",
      name: "DescribeDeclaredField",
      text: "",
    },
    declaredLabel,
    ...addField(),
    previousButton,
    {
      name: "SubmitButton",
      label: "{{{nextLabel}}}",
      type: "submit",
      component: "ButtonField",
      block: false,
    },
    resetButton,
  ],
});

previousButton.on("click", () => {
  form.setDirty(false);
  // globals.redirect(PreviousDestination(form));
  history.back();
});

form["submit"] = () => {
  saveValues(form);
  form.setDirty(false);
  globals.redirect("/currency-visitor-crew");
};

form.on("load", (f) => {
  loadValues(form);
});
form.on("mount", () => {
  const declaration = buildDeclaration();
  form
    .getField("DeclaredField")
    ._content._set(
      "text",
      declaration.declaration === "\nN/A"
        ? ""
        : "{{{declarationPreviousLabel}}}" + "\n" + declaration.declaration
    );
  form
    .getField("DescribeDeclaredField")
    ._content._set("text", declaration.describeLabel);
});
form.on("reset", (f) => {
  resetValues(form);
});
export { form as declarationOfGoods };
