import compiler from "mson/lib/compiler";
import { options } from "./options";
const app = compiler.newComponent({
  name: "LengthOfStayField",
  label: "{{{lengthOfStayLabel}}}",
  options: options.lengthOptions,
  component: "SelectField",
  fullWidth: true,
  autocomplete: false,
});

export { app as LengthOfStay };
