import compiler from "mson/lib/compiler";

const TranslatedCountryField = {
  component: "SelectField",
  schema: {
    component: "Form",
    fields: [
      {
        name: "options",
        docLevel: null,
      },
    ],
  },
  options: [
    { value: "AF", label: "{{{countries.AF}}}" },
    { value: "AX", label: "{{{countries.AX}}}" },
    { value: "AL", label: "{{{countries.AL}}}" },
    { value: "DZ", label: "{{{countries.DZ}}}" },
    { value: "AS", label: "{{{countries.AS}}}" },
    { value: "AD", label: "{{{countries.AD}}}" },
    { value: "AO", label: "{{{countries.AO}}}" },
    { value: "AI", label: "{{{countries.AI}}}" },
    { value: "AQ", label: "{{{countries.AQ}}}" },
    { value: "AG", label: "{{{countries.AG}}}" },
    { value: "AR", label: "{{{countries.AR}}}" },
    { value: "AM", label: "{{{countries.AM}}}" },
    { value: "AW", label: "{{{countries.AW}}}" },
    { value: "AU", label: "{{{countries.AU}}}" },
    { value: "AT", label: "{{{countries.AT}}}" },
    { value: "AZ", label: "{{{countries.AZ}}}" },
    { value: "BS", label: "{{{countries.BS}}}" },
    { value: "BH", label: "{{{countries.BH}}}" },
    { value: "BD", label: "{{{countries.BD}}}" },
    { value: "BB", label: "{{{countries.BB}}}" },
    { value: "BY", label: "{{{countries.BY}}}" },
    { value: "BE", label: "{{{countries.BE}}}" },
    { value: "BZ", label: "{{{countries.BZ}}}" },
    { value: "BJ", label: "{{{countries.BJ}}}" },
    { value: "BM", label: "{{{countries.BM}}}" },
    { value: "BT", label: "{{{countries.BT}}}" },
    { value: "BO", label: "{{{countries.BO}}}" },
    { value: "BQ", label: "{{{countries.BQ}}}" },
    { value: "BA", label: "{{{countries.BA}}}" },
    { value: "BW", label: "{{{countries.BW}}}" },
    { value: "BV", label: "{{{countries.BV}}}" },
    { value: "BR", label: "{{{countries.BR}}}" },
    { value: "IO", label: "{{{countries.IO}}}" },
    { value: "BN", label: "{{{countries.BN}}}" },
    { value: "BG", label: "{{{countries.BG}}}" },
    { value: "BF", label: "{{{countries.BF}}}" },
    { value: "BI", label: "{{{countries.BI}}}" },
    { value: "CV", label: "{{{countries.CV}}}" },
    { value: "KH", label: "{{{countries.KH}}}" },
    { value: "CM", label: "{{{countries.CM}}}" },
    { value: "CA", label: "{{{countries.CA}}}" },
    { value: "KY", label: "{{{countries.KY}}}" },
    { value: "CF", label: "{{{countries.CF}}}" },
    { value: "TD", label: "{{{countries.TD}}}" },
    { value: "CL", label: "{{{countries.CL}}}" },
    { value: "CN", label: "{{{countries.CN}}}" },
    { value: "CX", label: "{{{countries.CX}}}" },
    { value: "CC", label: "{{{countries.CC}}}" },
    { value: "CO", label: "{{{countries.CO}}}" },
    { value: "KM", label: "{{{countries.KM}}}" },
    { value: "CG", label: "{{{countries.CG}}}" },
    { value: "CD", label: "{{{countries.CD}}}" },
    { value: "CK", label: "{{{countries.CK}}}" },
    { value: "CR", label: "{{{countries.CR}}}" },
    { value: "CI", label: "{{{countries.CI}}}" },
    { value: "HR", label: "{{{countries.HR}}}" },
    { value: "CU", label: "{{{countries.CU}}}" },
    { value: "CW", label: "{{{countries.CW}}}" },
    { value: "CY", label: "{{{countries.CY}}}" },
    { value: "CZ", label: "{{{countries.CZ}}}" },
    { value: "DK", label: "{{{countries.DK}}}" },
    { value: "DJ", label: "{{{countries.DJ}}}" },
    { value: "DM", label: "{{{countries.DM}}}" },
    { value: "DO", label: "{{{countries.DO}}}" },
    { value: "EC", label: "{{{countries.EC}}}" },
    { value: "EG", label: "{{{countries.EG}}}" },
    { value: "SV", label: "{{{countries.SV}}}" },
    { value: "GQ", label: "{{{countries.GQ}}}" },
    { value: "ER", label: "{{{countries.ER}}}" },
    { value: "EE", label: "{{{countries.EE}}}" },
    { value: "SZ", label: "{{{countries.SZ}}}" },
    { value: "ET", label: "{{{countries.ET}}}" },
    { value: "FK", label: "{{{countries.FK}}}" },
    { value: "FO", label: "{{{countries.FO}}}" },
    { value: "FJ", label: "{{{countries.FJ}}}" },
    { value: "FI", label: "{{{countries.FI}}}" },
    { value: "FR", label: "{{{countries.FR}}}" },
    { value: "GF", label: "{{{countries.GF}}}" },
    { value: "PF", label: "{{{countries.PF}}}" },
    { value: "TF", label: "{{{countries.TF}}}" },
    { value: "GA", label: "{{{countries.GA}}}" },
    { value: "GM", label: "{{{countries.GM}}}" },
    { value: "GE", label: "{{{countries.GE}}}" },
    { value: "DE", label: "{{{countries.DE}}}" },
    { value: "GH", label: "{{{countries.GH}}}" },
    { value: "GI", label: "{{{countries.GI}}}" },
    { value: "GR", label: "{{{countries.GR}}}" },
    { value: "GL", label: "{{{countries.GL}}}" },
    { value: "GD", label: "{{{countries.GD}}}" },
    { value: "GP", label: "{{{countries.GP}}}" },
    { value: "GU", label: "{{{countries.GU}}}" },
    { value: "GT", label: "{{{countries.GT}}}" },
    { value: "GG", label: "{{{countries.GG}}}" },
    { value: "GN", label: "{{{countries.GN}}}" },
    { value: "GW", label: "{{{countries.GW}}}" },
    { value: "GY", label: "{{{countries.GY}}}" },
    { value: "HT", label: "{{{countries.HT}}}" },
    { value: "HM", label: "{{{countries.HM}}}" },
    { value: "VA", label: "{{{countries.VA}}}" },
    { value: "HN", label: "{{{countries.HN}}}" },
    { value: "HK", label: "{{{countries.HK}}}" },
    { value: "HU", label: "{{{countries.HU}}}" },
    { value: "IS", label: "{{{countries.IS}}}" },
    { value: "IN", label: "{{{countries.IN}}}" },
    { value: "ID", label: "{{{countries.ID}}}" },
    { value: "IR", label: "{{{countries.IR}}}" },
    { value: "IQ", label: "{{{countries.IQ}}}" },
    { value: "IE", label: "{{{countries.IE}}}" },
    { value: "IM", label: "{{{countries.IM}}}" },
    { value: "IL", label: "{{{countries.IL}}}" },
    { value: "IT", label: "{{{countries.IT}}}" },
    { value: "JM", label: "{{{countries.JM}}}" },
    { value: "JP", label: "{{{countries.JP}}}" },
    { value: "JE", label: "{{{countries.JE}}}" },
    { value: "JO", label: "{{{countries.JO}}}" },
    { value: "KZ", label: "{{{countries.KZ}}}" },
    { value: "KE", label: "{{{countries.KE}}}" },
    { value: "KI", label: "{{{countries.KI}}}" },
    { value: "KP", label: "{{{countries.KP}}}" },
    { value: "KR", label: "{{{countries.KR}}}" },
    { value: "KW", label: "{{{countries.KW}}}" },
    { value: "KG", label: "{{{countries.KG}}}" },
    { value: "LA", label: "{{{countries.LA}}}" },
    { value: "LV", label: "{{{countries.LV}}}" },
    { value: "LB", label: "{{{countries.LB}}}" },
    { value: "LS", label: "{{{countries.LS}}}" },
    { value: "LR", label: "{{{countries.LR}}}" },
    { value: "LY", label: "{{{countries.LY}}}" },
    { value: "LI", label: "{{{countries.LI}}}" },
    { value: "LT", label: "{{{countries.LT}}}" },
    { value: "LU", label: "{{{countries.LU}}}" },
    { value: "MO", label: "{{{countries.MO}}}" },
    { value: "MK", label: "{{{countries.MK}}}" },
    { value: "MG", label: "{{{countries.MG}}}" },
    { value: "MW", label: "{{{countries.MW}}}" },
    { value: "MY", label: "{{{countries.MY}}}" },
    { value: "MV", label: "{{{countries.MV}}}" },
    { value: "ML", label: "{{{countries.ML}}}" },
    { value: "MT", label: "{{{countries.MT}}}" },
    { value: "MH", label: "{{{countries.MH}}}" },
    { value: "MQ", label: "{{{countries.MQ}}}" },
    { value: "MR", label: "{{{countries.MR}}}" },
    { value: "MU", label: "{{{countries.MU}}}" },
    { value: "YT", label: "{{{countries.YT}}}" },
    { value: "MX", label: "{{{countries.MX}}}" },
    { value: "FM", label: "{{{countries.FM}}}" },
    { value: "MD", label: "{{{countries.MD}}}" },
    { value: "MC", label: "{{{countries.MC}}}" },
    { value: "MN", label: "{{{countries.MN}}}" },
    { value: "ME", label: "{{{countries.ME}}}" },
    { value: "MS", label: "{{{countries.MS}}}" },
    { value: "MA", label: "{{{countries.MA}}}" },
    { value: "MZ", label: "{{{countries.MZ}}}" },
    { value: "MM", label: "{{{countries.MM}}}" },
    { value: "NA", label: "{{{countries.NA}}}" },
    { value: "NR", label: "{{{countries.NR}}}" },
    { value: "NP", label: "{{{countries.NP}}}" },
    { value: "NL", label: "{{{countries.NL}}}" },
    { value: "NC", label: "{{{countries.NC}}}" },
    { value: "NZ", label: "{{{countries.NZ}}}" },
    { value: "NI", label: "{{{countries.NI}}}" },
    { value: "NE", label: "{{{countries.NE}}}" },
    { value: "NG", label: "{{{countries.NG}}}" },
    { value: "NU", label: "{{{countries.NU}}}" },
    { value: "NF", label: "{{{countries.NF}}}" },
    { value: "MP", label: "{{{countries.MP}}}" },
    { value: "NO", label: "{{{countries.NO}}}" },
    { value: "OM", label: "{{{countries.OM}}}" },
    { value: "PK", label: "{{{countries.PK}}}" },
    { value: "PW", label: "{{{countries.PW}}}" },
    { value: "PS", label: "{{{countries.PS}}}" },
    { value: "PA", label: "{{{countries.PA}}}" },
    { value: "PG", label: "{{{countries.PG}}}" },
    { value: "PY", label: "{{{countries.PY}}}" },
    { value: "PE", label: "{{{countries.PE}}}" },
    { value: "PH", label: "{{{countries.PH}}}" },
    { value: "PN", label: "{{{countries.PN}}}" },
    { value: "PL", label: "{{{countries.PL}}}" },
    { value: "PT", label: "{{{countries.PT}}}" },
    { value: "PR", label: "{{{countries.PR}}}" },
    { value: "QA", label: "{{{countries.QA}}}" },
    { value: "RE", label: "{{{countries.RE}}}" },
    { value: "RO", label: "{{{countries.RO}}}" },
    { value: "RU", label: "{{{countries.RU}}}" },
    { value: "RW", label: "{{{countries.RW}}}" },
    { value: "BL", label: "{{{countries.BL}}}" },
    { value: "SH", label: "{{{countries.SH}}}" },
    { value: "KN", label: "{{{countries.KN}}}" },
    { value: "LC", label: "{{{countries.LC}}}" },
    { value: "MF", label: "{{{countries.MF}}}" },
    { value: "PM", label: "{{{countries.PM}}}" },
    { value: "VC", label: "{{{countries.VC}}}" },
    { value: "WS", label: "{{{countries.WS}}}" },
    { value: "SM", label: "{{{countries.SM}}}" },
    { value: "ST", label: "{{{countries.ST}}}" },
    { value: "SA", label: "{{{countries.SA}}}" },
    { value: "SN", label: "{{{countries.SN}}}" },
    { value: "RS", label: "{{{countries.RS}}}" },
    { value: "SC", label: "{{{countries.SC}}}" },
    { value: "SL", label: "{{{countries.SL}}}" },
    { value: "SG", label: "{{{countries.SG}}}" },
    { value: "SX", label: "{{{countries.SX}}}" },
    { value: "SK", label: "{{{countries.SK}}}" },
    { value: "SI", label: "{{{countries.SI}}}" },
    { value: "SB", label: "{{{countries.SB}}}" },
    { value: "SO", label: "{{{countries.SO}}}" },
    { value: "ZA", label: "{{{countries.ZA}}}" },
    { value: "GS", label: "{{{countries.GS}}}" },
    { value: "SS", label: "{{{countries.SS}}}" },
    { value: "ES", label: "{{{countries.ES}}}" },
    { value: "LK", label: "{{{countries.LK}}}" },
    { value: "SD", label: "{{{countries.SD}}}" },
    { value: "SR", label: "{{{countries.SR}}}" },
    { value: "SJ", label: "{{{countries.SJ}}}" },
    { value: "SE", label: "{{{countries.SE}}}" },
    { value: "CH", label: "{{{countries.CH}}}" },
    { value: "SY", label: "{{{countries.SY}}}" },
    { value: "TW", label: "{{{countries.TW}}}" },
    { value: "TJ", label: "{{{countries.TJ}}}" },
    { value: "TZ", label: "{{{countries.TZ}}}" },
    { value: "TH", label: "{{{countries.TH}}}" },
    { value: "TL", label: "{{{countries.TL}}}" },
    { value: "TG", label: "{{{countries.TG}}}" },
    { value: "TK", label: "{{{countries.TK}}}" },
    { value: "TO", label: "{{{countries.TO}}}" },
    { value: "TT", label: "{{{countries.TT}}}" },
    { value: "TN", label: "{{{countries.TN}}}" },
    { value: "TR", label: "{{{countries.TR}}}" },
    { value: "TM", label: "{{{countries.TM}}}" },
    { value: "TC", label: "{{{countries.TC}}}" },
    { value: "TV", label: "{{{countries.TV}}}" },
    { value: "UG", label: "{{{countries.UG}}}" },
    { value: "UA", label: "{{{countries.UA}}}" },
    { value: "AE", label: "{{{countries.AE}}}" },
    { value: "GB", label: "{{{countries.GB}}}" },
    { value: "US", label: "{{{countries.US}}}" },
    { value: "UM", label: "{{{countries.UM}}}" },
    { value: "UY", label: "{{{countries.UY}}}" },
    { value: "UZ", label: "{{{countries.UZ}}}" },
    { value: "VU", label: "{{{countries.VU}}}" },
    { value: "VE", label: "{{{countries.VE}}}" },
    { value: "VN", label: "{{{countries.VN}}}" },
    { value: "VG", label: "{{{countries.VG}}}" },
    { value: "VI", label: "{{{countries.VI}}}" },
    { value: "WF", label: "{{{countries.WF}}}" },
    { value: "EH", label: "{{{countries.EH}}}" },
    { value: "YE", label: "{{{countries.YE}}}" },
    { value: "ZM", label: "{{{countries.ZM}}}" },
    { value: "ZW", label: "{{{countries.ZW}}}" },
  ],
};

export default TranslatedCountryField;

compiler.registerComponent("TranslatedCountryField", TranslatedCountryField);
