import compiler from "mson/lib/compiler";
import { options } from "./options";

const stayFields = options.stayOptions.map((o) => {
  return {
    label: o.label,
    value: false,
    // value: o.value,
    name: o.value + "Field",
    component: "BooleanField",
  };
});
const fields = [
  {
    name: "PlaceOfStayLabel",
    component: "Text",
    text: "{{{placeOfStayLabel}}}",
  },
  ...stayFields,
];

export { fields as PlaceOfStay };
