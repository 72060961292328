import compiler from "mson/lib/compiler";
import { options } from "./options";

const app = compiler.newComponent({
  name: "TripToGuamField",
  label: "{{{tripDescription}}}",
  // required: true,
  options: options.tripOptions,
  component: "SelectField",
  fullWidth: true,
  autocomplete: false,
});

export { app as TripToGuam };
