// import React from 'react';
// import Checkbox from '@material-ui/core/Checkbox';
// import Component from 'mson/lib/component';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import CommonField from "../mson-react-bootstrap/fields/common-field";
import attach from "../mson-react-bootstrap/attach";
import DisplayValueTypography from "../mson-react-bootstrap/fields/display-value-typography";

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { LangContext } from "../lib/LangContext";

class SingleCheckboxField extends React.PureComponent {
  static contextType = LangContext;
  handleChange = (event) => {
    this.props.component.setValue(event.target.checked);
  };

  render() {
    const {
      value,
      disabled,
      component,
      editable,
      useDisplayValue,
      label,
      required,
      err,
    } = this.props;
    const { translate } = this.context;

    let hideLabelUI = null;

    let fld = null;
    const error = required && value === undefined;
    const firstErr = component.getFirstErr();
    if (editable && !useDisplayValue) {
      hideLabelUI = true;
      fld = (
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={value ? value : false}
                onChange={this.handleChange}
                name="Yes"
              />
            }
            style={err ? { color: "red" } : null}
            label={translate(label)}
            labelPlacement="end"
          />
          {err ? (
            <FormHelperText error>
              {translate(`{{{${firstErr}}}}`)}
            </FormHelperText>
          ) : null}
        </span>
      );
    } else {
      fld = (
        <DisplayValueTypography>
          {component.getDisplayValue()}
        </DisplayValueTypography>
      );
    }

    return (
      <CommonField component={component} hideLabelUI={hideLabelUI}>
        {fld}
      </CommonField>
    );
  }
}
const returnValue = attach([
  "value",
  "err",
  "disabled",
  "required",
  "editable",
  "useDisplayValue",
  "label",
  "err",
])(SingleCheckboxField);

export default returnValue;
