import compiler from "mson/lib/compiler";
import { options } from "./options";

const app = compiler.newComponent({
  name: "TravelArrangementsField",
  label: "{{{arrangementsLabel}}}",
  // required: true,
  options: options.arrangementOptions,
  component: "SelectField",
  fullWidth: true,
  autocomplete: false,
});

export { app as TravelArrangements };
