// import React from 'react';
// import Checkbox from '@material-ui/core/Checkbox';
// import Component from 'mson/lib/component';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import CommonField from "../mson-react-bootstrap/fields/common-field";
import attach from "../mson-react-bootstrap/attach";
import DisplayValueTypography from "../mson-react-bootstrap/fields/display-value-typography";

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { LangContext } from "../lib/LangContext";

class CheckboxField extends React.PureComponent {
  static contextType = LangContext;
  handleChange = (event) => {
    let result = true;
    if (event.target.name === "Yes" && event.target.checked) {
      result = true;
    } else if (event.target.name === "No" && event.target.checked) {
      result = false;
    }
    this.props.component.setValue(result);
  };

  render() {
    const {
      value,
      disabled,
      component,
      editable,
      useDisplayValue,
      label,
      required,
    } = this.props;
    const { translate } = this.context;

    let hideLabelUI = null;

    let fld = null;
    const error = required && value === undefined;
    if (editable && !useDisplayValue) {
      hideLabelUI = true;
      fld = (
        <FormControl component="fieldset" error={error}>
          <FormLabel component="legend">{translate(label)}</FormLabel>
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value ? value : false}
                  onChange={this.handleChange}
                  name="Yes"
                />
              }
              label={translate("{{{Yes}}}")}
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={value === false ? true : false}
                  onChange={this.handleChange}
                  name="No"
                />
              }
              label={translate("{{{No}}}")}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
      );
    } else {
      fld = (
        <DisplayValueTypography>
          {component.getDisplayValue()}
        </DisplayValueTypography>
      );
    }

    return (
      <CommonField component={component} hideLabelUI={hideLabelUI}>
        {fld}
      </CommonField>
    );
  }
}
const returnValue = attach([
  "value",
  "err",
  "disabled",
  "required",
  "editable",
  "useDisplayValue",
  "label",
])(CheckboxField);

export default returnValue;
