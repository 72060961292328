import React from "react";
import classNames from "classnames";
// import Select from 'react-select';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "../icon";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { LangContext } from "../../lib/LangContext";

const styles = (theme) => ({
  input: {
    display: "flex",
    padding: 0,
    minWidth: 240, // A good default for most labels
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",

    // Adjust for MUI label
    marginTop: theme.spacing(2) + 5,
  },
  chip: {
    margin: `${theme.spacing()}px ${theme.spacing() / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  // placeholder: {
  //   position: 'absolute',
  //   left: 2,
  //   fontSize: theme.typography.subtitle1.fontSize
  // },
  paper: {
    // position: 'absolute',
    zIndex: 1,

    // Adjust for MUI label
    marginTop: theme.spacing(1),
  },
  root: {
    // Allow for help icon to be placed on right of field
    display: "inline-flex",
    marginTop: "16px",
  },
  fullWidth: {
    width: "100%",
  },
  disabled: {
    color: theme.palette.text.secondary,
  },
});

class AutoCompleteSelect extends React.Component {
  static contextType = LangContext;
  render() {
    const {
      classes,
      options,
      isClearable,
      placeholder,
      onChange,
      onBlur,
      onFocus,
      onInputChange,
      value,
      isDisabled,
      fullWidth,
      isMulti,
    } = this.props;
    const { translate } = this.context;
    return (
      <Autocomplete
        className={classNames(classes.root, fullWidth && classes.fullWidth)}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        options={options}
        value={value}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
            className={classes.input}
          />
        )}
        getOptionLabel={(option) => translate(option.label || "")}
      />
      // <Select
      //   className={classNames(classes.root, fullWidth && classes.fullWidth)}
      //   classes={classes}
      //   styles={selectStyles}
      //   options={options}
      //   components={components}
      //   value={value}
      //   onChange={onChange}
      //   onBlur={onBlur}
      //   onFocus={onFocus}
      //   onInputChange={onInputChange}
      //   placeholder={placeholder}
      //   isClearable={isClearable}
      //   isDisabled={isDisabled}
      //   fullWidth={fullWidth}
      //   isMulti={isMulti}
      //   // Needed so that the menu is not clipped by the app's root level overflow:hidden or when it
      //   // appears in a dialog window
      //   menuPortalTarget={document.body}
      // />
    );
  }
}

export default withStyles(styles)(AutoCompleteSelect);
