import compiler from "mson/lib/compiler";
import globals from "mson/lib/globals";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

import { loadValues, saveValues, resetValues, resetForm } from "./session";
import BuildDeclaration from "./buildDeclaration";

const previousButton = compiler.newComponent({
  name: "PreviousButton",
  label: "{{{prevLabel}}}",
  type: "button",
  component: "ButtonField",
  block: false,
});
const submitButton = compiler.newComponent({
  name: "SubmitButton",
  label: "{{{nextLabel}}}", //TODO
  type: "button",
  component: "ButtonField",
  block: false,
});
const resetButton = compiler.newComponent({
  name: "ResetButton",
  label: "{{{resetLabel}}}", //TODO
  type: "reset",
  component: "ButtonField",
  block: true,
});
resetButton.on("click", () => {
  form._events.reset();
  resetForm(form);
});

const DescriptionField = compiler.newComponent({
  component: "Text",
  name: "DescriptionField",
  text: "{{{heading}}}{{{packingListTitleLabel}}}",
});

const form = compiler.newComponent({
  component: "Form",
  name: "app.PackingList",
  fields: [
    DescriptionField,
    {
      component: "Text",
      text: "{{{EveryFieldLabel}}}",
      //   text: "You must answer every field to continue.",
      name: "EveryField",
    },
    {
      component: "CheckboxField",
      label: "{{{substancesLabel}}}",
      name: "SubstancesField",
      required: true,
    },
    {
      name: "FirearmsField",
      label: "{{{firearmsLabel}}}",
      component: "CheckboxField",
    },
    {
      name: "ExplosivesField",
      label: "{{{explosivesLabel}}}",
      component: "CheckboxField",
    },
    {
      name: "AnimalsField",
      label: "{{{animalsLabel}}}",
      component: "CheckboxField",
    },
    {
      name: "AnimalProductsField",
      label: "{{{animalProductsLabel}}}",
      component: "CheckboxField",
    },
    {
      name: "PlantsField",
      label: "{{{plantsLabel}}}",
      component: "CheckboxField",
    },
    { name: "SoilField", label: "{{{soilLabel}}}", component: "CheckboxField" },
    {
      name: "ServiceAnimalField",
      label: "{{{serviceAnimalLabel}}}",
      component: "CheckboxField",
    },
    {
      name: "CigarettesField",
      label: "{{{cigarettesLabel}}}",
      component: "CheckboxField",
    },
    {
      name: "AlcoholField",
      label: "{{{alcoholLabel}}}",
      component: "CheckboxField",
    },
    previousButton,
    submitButton,
    resetButton,
    {
      component: "LatinOnlyTextField",
      hidden: true,
      disabled: true,
      value: "🚫 {{{EveryFieldLabel}}}",
      label: "🚫 {{{EveryFieldLabel}}}",
      fullWidth: true,
      name: "ErrorText",
    },
    {
      component: "LatinOnlyTextField",
      hidden: true,
      value: "",
      name: "CrewType",
    },
  ],
});

previousButton.on("click", () => {
  form.setDirty(false);
  // let destination = PreviousDestination(form);
  history.back();
  // window.setTimeout(function(){
  //     globals.redirect(destination);
  // }, 0);
});

const submit = () => {
  saveValues(form);
  const dec = BuildDeclaration();
  form.setDirty(false);
  globals.redirect(`/declaration-of-goods`);
};

submitButton.on("click", () => {
  form.getField("ErrorText")._set("hidden", true);
  const fields = [
    "SubstancesField",
    "FirearmsField",
    "ExplosivesField",
    "AnimalsField",
    "AnimalProductsField",
    "PlantsField",
    "SoilField",
    "ServiceAnimalField",
    "CigarettesField",
    "AlcoholField",
  ];
  const check = fields.filter((field) => {
    return form.getField(field)._value === null;
  });
  if (check.length === 0) {
    form.getField("ErrorText")._set("hidden", true);
    submit();
  } else {
    form.getField("ErrorText")._set("hidden", false);
  }
});

form.on("load", (f) => {
  if (globals._route.parameters["crewType"]) {
    form.getField("CrewType").setValue(globals._route.parameters["crewType"]);
  }
  loadValues(form);
});
form.on("reset", (f) => {
  window.setTimeout(function () {
    resetValues(form);
  }, 0);
});

export { form as visitorPackingList };
